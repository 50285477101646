import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import LandingBuilder from './LandingBuilder';

const calcZoom = ({ width }) => width < 1200 ? width / 1200 : 1;

const StyledContainer = styled.div`
    overflow: hidden;
    & > div {
        overflow: hidden;
        zoom: ${calcZoom};
        -moz-transform: scale(${calcZoom});
        -moz-transform-origin: top;
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            width: calc(100% + 19%);
            min-width: 1130px;
        }
        .settings-sidebar {
            display: none;
        }
    }
`;

export default class PreviewTemplate extends Component {
    static propTypes = {
        template: PropTypes.string,
        isAncor: PropTypes.bool
    };

    constructor() {
        super();

        this.state = {
            width: null
        };
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener('resize', this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    updateDimensions = () => {
        const { width } = document.getElementById('preview').getBoundingClientRect();

        this.setState({ width });
    }

    render() {
        const { template, data, settings, isAncor } = this.props;
        const { width } = this.state;

        return <StyledContainer width={width} id='preview' className='preview-mode'>
            <LandingBuilder template={template} data={data} settings={settings} isAncor={isAncor} />
        </StyledContainer>;
    }
}
