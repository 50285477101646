import React, { Component } from 'react';
import { Radio } from 'antd';
import styled from 'styled-components';

const MODES = [
    { name: 'Текст', value: 'text' },
    { name: 'Текст с иконками', value: 'icons' }
];

const Wrapper = styled.div`
    margin-top: 10px;
`;

export default class GrayMainModePicker extends Component {
    onChange = e => this.props.onChange(e.target.value);

    render() {
        return <Wrapper>
            <Radio.Group
                value={this.props.value}
                onChange={this.onChange}>
                { MODES.map(mode =>
                    <Radio key={mode.value} value={mode.value}>
                        { mode.name }
                    </Radio>
                )}
            </Radio.Group>
        </Wrapper>;
    }
}
