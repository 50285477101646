import React, { Component } from 'react';
import { assoc, compose, mergeDeepRight, fromPairs, toPairs, contains, filter, pathOr } from 'ramda';
import styled from 'styled-components';
import Rollbar from 'rollbar';
import cx from 'classnames';
import qs from 'qs';
import axios from 'axios';
import { message, Spin } from 'antd';
import { path } from 'ramda';

import PreviewTemplateModal from '../PreviewTemplateModal';
import { TEMPLATES } from '../../constants/templates';
import { getHTML } from '../../constants/html';
import { SETTINGS } from '../../constants/settings';
import { getThemesTitle } from '../../utils/themes';
import FileSettingsContext from '../contexts/FileSettingsContext';

const DebugButton = styled.span`
    position: absolute;
    display: block;
    width: 50px;
    height: 50px;
`

export default WrappedComponent =>
    class extends Component {
        state = {
            loaded: false,
            template: null,
            theme: {},
            preview: null,
            data: {},
            settings: {},
            params: {},
            themeLoading: false,
            fileSettings: {}
        };

        static defaultProps = {
            templates: []
        };

        defaultSettings = {};

        componentDidMount() {
            window.setData = this.setData;
            window.getData = this.getData;
            // Notify desktop
            document.title = 'Builder ready';

            window.addEventListener('message', this.receiveData);

            if (process.env.NODE_ENV === 'development') {
                this.setData('');
            }
        }

        componentDidUpdate(prevProps, prevState) {
            if (this.state.template !== prevState.template) {
                this.scrollToTop();
            }
        }

        componentWillUnmount() {
            window.removeEventListener('message', this.receiveData);
        }

        receiveData = ({ data }) => {
            switch(data.command) {
                case 'setLanding':
                    this.setData(data.landing);
                    data.fileSettings && this.setState({ fileSettings: data.fileSettings });
                    this.iframeUrl = data.iframeUrl;
                    this.defaultSettings = data.defaultSettings;
                    break;
                case 'setParams':
                    this.setParams(data.params);
                    break;
                case 'getData':
                    window.parent.postMessage({
                        fromLanding: true,
                        data: this.getData()
                    }, this.iframeUrl);
                    break;
                default:
                    return;
            }
        }

        setData = string => {
            if (this.state.loaded) {
                return console.log('Конструктор уже загружен');
            }
            if (string) {
                let data;
                try {
                    data = JSON.parse(string);
                } catch (e) {
                    if (process.env.NODE_ENV === 'production') {
                        Rollbar.debug(`Invalid JSON: ${string}`, e);
                    }

                    alert('Неверный JSON');
                }

                if (data) {
                    this.setState({
                        loaded: true,
                        template: data.template || {},
                        theme: data.theme || {},
                        data: mergeDeepRight(getHTML(data.template), data.data.description),
                        settings: this.getDefaultSettings(data.data.settings, data.template),
                        params: data.params || {},
                    });
                }
            } else {
                this.setState({
                    loaded: true,
                });
            }
        }

        setParams = (params = {}) => {
            this.setState({
                params,
            });
        }

        getData = () => {
            const { template, theme, data, settings } = this.state;

            return template ? JSON.stringify({
                template,
                theme,
                data: {
                    description: data,
                    settings
                }
            }) : '';
        }

        getDefaultSettings(settings, template = this.state.template) {
            return compose(
                mergeDeepRight(fromPairs(
                    toPairs(SETTINGS[template]).map(([ key, item ]) =>
                        ([ key, fromPairs(item.map(i => ([ i.name, i.value ])))])
                    )
                )),
                mergeDeepRight(this.defaultSettings),
            )(settings);
        }

        onDataChange = data => this.setState({ data });

        onSettingsChange = settings => this.setState({ settings });

        onReset = async () => {
            const dataSettings = await this.getHTMLTemplate(this.state.template, this.state.theme[this.state.template]);

            this.setState({
                ...(dataSettings),
            });
        }

        getTheme = (template, theme) => {
            this.setState({
                themeLoading: true,
            });

            this.currentThemeLoader = axios.CancelToken.source();

            return (
                axios.get(
                    `${process.env.PUBLIC_URL}/templates/${template}/${theme}.json`,
                    { cancelToken: this.currentThemeLoader.token }
                )
                    .then((data) => ({
                        data: mergeDeepRight(getHTML(data.template), data.data.description),
                        settings: this.getDefaultSettings(data.data.settings, data.template),
                    }))
                    .catch(error => {
                        if (path(['origin', 'message'], error) !== 'cancel') {
                            message.error('Не удалось загрузить шаблон');
                        }

                        return Promise.reject(error);
                    })
                    .finally(() => {
                        this.setState({
                            themeLoading: false,
                        });

                        this.currentThemeLoader = null;
                    })
            );
        }

        cancelGetTheme = () => {
            if (this.currentThemeLoader) {
                this.currentThemeLoader.cancel('cancel');
            }
        }

        getHTMLTemplate = async (template, theme = 'default') => {
            this.cancelGetTheme();

            return (
                theme !== 'default'
                    ? await this.getTheme(template, theme)
                    : ({
                        data: getHTML(template),
                        settings: this.getDefaultSettings({}, template),
                    })
            );
        }

        selectTemplate = async (template, theme) => {
            const dataSettings = await this.getHTMLTemplate(template, theme);

            this.setState({
                template,
                ...dataSettings,
            });
        }

        selectPreviewTemplate = () => {
            this.setState(prev => ({
                template: prev.preview,
                preview: null
            }));
        }

        openModal = async (template, theme) => {
            const dataSettings = await this.getHTMLTemplate(template, theme);

            this.setState({
                preview: template,
                ...dataSettings,
            });
        }

        closeModal = () => {
            this.cancelGetTheme();
            this.setState({
                preview: null,
                data: {},
                settings: {}
            })
        };

        setTheme = (template, theme) => {
            this.setState({
                theme: assoc(template, theme, this.state.theme)
            });
        }

        scrollToTop = () => window.scrollTo(0, 0);

        renderTemplateSelector() {
            const { preview, data, settings, themeLoading, params: { hideTemplates = [] } } = this.state;
            const hiddenTemplates = this.props.isAncor ? hideTemplates : ['ancor', ...hideTemplates];
            const templates = filter(template => !contains(template.value, hiddenTemplates), TEMPLATES);

            return <div>
                <div className='template-wrap'>
                    <Spin spinning={themeLoading} className='theme-spinner'>
                        <h1><small>Выберите подходящий шаблон и адаптируйте его под ваши цели.</small></h1>
                        <div className='template-select'>
                            { templates.map(({ value, themes }) =>
                                <div className='template-col' key={`template-${value} theme-${this.state.theme[value]}`}>
                                    <div className={`template-col-item template-${value} theme-${this.state.theme[value]}`}>
                                        <div className='preview'>
                                            <div className='preview-img'></div>
                                            <div className='themes-title'>{getThemesTitle(value)}</div>
                                            <div className='controls text-center'>
                                                <button className='button' onClick={() => this.selectTemplate(value, this.state.theme[value])}>
                                                    Выбрать
                                                </button>
                                                <button className='button button-white' onClick={() => this.openModal(value, this.state.theme[value])}>Предпросмотр</button>
                                            </div>
                                        </div>
                                        <div className='body'>
                                            <div className='themes'>
                                                <div className='themes-select'>
                                                    { themes.map((theme) => {
                                                        return (
                                                            <div
                                                                key={theme}
                                                                className={cx(`theme-img theme-img-${value} theme-img-${theme}`, {
                                                                    selected: theme === (this.state.theme[value] || 'default') ,
                                                                })}
                                                                onClick={() => this.setTheme(value, theme)}
                                                            />
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                            <p className='description'>

                                            </p>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <PreviewTemplateModal
                            template={preview}
                            templates={templates}
                            theme={this.state.theme[preview]}
                            data={data}
                            settings={settings}
                            onClose={this.closeModal}
                            onSetTheme={this.setTheme}
                            onSelect={this.selectPreviewTemplate}
                            renderContent={this.renderPreviewContent}
                            onChangePreview={this.openModal}
                            themeLoading={themeLoading}
                            isAncor={this.props.isAncor} />
                    </Spin>
                </div>
            </div>;
        }

        render() {
            const { template, data, settings, loaded, params } = this.state;
            const { location } = this.props;

            if (!loaded) {
                const urlParams = qs.parse(pathOr('', ['search'], location).replace('?', ''));

                return <DebugButton onClick={() => this.setData(urlParams.data)} />
            }

            if (!template) {
                return this.renderTemplateSelector();
            }

            return (
                <FileSettingsContext.Provider value={this.state.fileSettings}>
                    <WrappedComponent
                        template={template}
                        settings={settings}
                        data={data}
                        params={params}
                        onChangeTemplate={this.selectTemplate}
                        onDataChange={this.onDataChange}
                        onSettingsChange={this.onSettingsChange}
                        onReset={this.onReset}
                    />
                </FileSettingsContext.Provider>
            );
        }
    }
