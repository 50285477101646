import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import styled from 'styled-components';
import { range, dropLast, append, path } from 'ramda';

import DataContext from '../../context/DataContext';
import { LIME_OPTION_HTML } from '../../constants/templates/lime';
import User from '../../assets/img/user.svg';

const Container = styled.div`
    margin-top: 10px;
    .ant-select {
        width: 100%;
        border: 1px solid #e4e3e3;
        border-radius: 1px;
    }
`;

const rows = range(1, 5);

export default class SelectElementsAmount extends Component {
    static propTypes = {
        name: PropTypes.string,
        onChange: PropTypes.func
    };

    onChange = (value, { onChange, data }) => {
        let options = path((this.props.field || 'options').split('.'), data);
        const diff = options.length - value * path(['rows'], this.props.settings);

        if (this.props.value > value) {
            options = dropLast(diff, options);
        } else {
            for (let i = 0; i < Math.abs(diff); i++) {
                options = append({
                    html: LIME_OPTION_HTML,
                    image: User
                }, options);
            }
        }

        this.props.onChange(value);
        onChange(this.props.field || 'options', options);
    }

    render() {
        const { name, value } = this.props;

        return <Container>
            <DataContext.Consumer>
                { dataProps =>
                    <Select value={value} onChange={value => this.onChange(value, dataProps)}>
                        { rows.map(option =>
                            <Select.Option key={`${name}-${option}`} value={option}>
                                { option }
                            </Select.Option>
                        )}
                    </Select>
                }
            </DataContext.Consumer>
        </Container>;
    }
}
