import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Select, Modal } from 'antd';
import { assoc } from 'ramda';
import styled from 'styled-components';
import { confirmAlert } from 'react-confirm-alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import { DEVICES } from '../../constants/devices';
import EditContext from '../../context/EditContext';
import Preview from '../Preview';
import CommonSettingsFields from '../settingsFields/CommonSettingsFields';

const SettingsModal = styled(Modal)`
    width: calc(100% - 30px) !important;
    max-width: 1200px;
`;

export default WrappedComponent =>
    class extends Component {
        static propTypes = {
            onChangeTemplate: PropTypes.func,
            template: PropTypes.string,
            children: PropTypes.node,
            onReset: PropTypes.func
        };

        state = {
            edit: true,
            previewDevice: 'iphone',
            showSettings: false,
            savePending: false,
        };

        reset = () => {
            const reset = window.confirm('Вы уверены, что хотите сбросить настройки?');

            if (reset) {
                this.props.onReset();
            }
        }

        onTogglePreview = () => this.setState(prev => ({ edit: !prev.edit }));

        onChangePreviewDevice = previewDevice => this.setState({ previewDevice });

        changeTemplate = () => {
            const change = window.confirm('Вы уверены, что хотите поменять шаблон? Все ваши текущие настройки будут сброшены');

            if (change) {
                this.props.onChangeTemplate();
            }
        }

        openSettings = () => {
            this.sidebar.onClose();
            this.setState({ showSettings: true });
            window.parent.postMessage({
                fromLanding: true,
                showSettings: true
            }, '*');
        }

        closeSettings = () => {
            if (this.settings.isDirty()) {
                confirmAlert({
                    title: 'Настройки',
                    message: 'Вы уверены, что хотите закрыть настройки? Все несохраненные изменения будут сброшены.',
                    buttons: [
                        { label: 'Да', onClick: this.close },
                        { label: 'Нет' }
                    ]
                });
            } else {
                this.close();
            }
        }

        close = () => {
            this.setState({ showSettings: false });
            window.parent.postMessage({
                fromLanding: true,
                showSettings: false
            }, '*');
        }

        saveSettings = values => {
            const { settings, onSettingsChange } = this.props;

            onSettingsChange(assoc('common', values, settings));
            this.close();
        }

        render() {
            const { data, settings, params, template } = this.props;
            const { edit, previewDevice, showSettings, savePending } = this.state;

            return <Fragment>
                <div className='settings-toolbar-wrapper'>
                    <div className='settings-toolbar-height' />
                    <div className='settings-toolbar' ref={node => this.toolbar = node}>
                        <div className='toolbar-content'>
                            <div className='toolbar-center'>
                                { !edit &&
                                    <Select
                                        className='toolbar-select'
                                        value={previewDevice}
                                        onChange={this.onChangePreviewDevice}
                                        getPopupContainer={() => this.toolbar}>
                                        { DEVICES.map(({ value, label, description }) =>
                                            <Select.Option key={`device-option-${value}`} value={value}>
                                                { label }
                                                <small className="select-description">{ description }</small>
                                            </Select.Option>
                                        )}
                                    </Select>
                                }
                                <button className='settings-toolbar-button' onClick={this.onTogglePreview}>
                                    { edit ? 'Предпросмотр' : 'Вернуться к редактированию' }
                                </button>
                            </div>
                            { edit &&
                                <div className='toolbar-right'>
                                    <button className='settings-toolbar-button' onClick={this.openSettings}>
                                        Настройки
                                    </button>
                                    <button className='settings-toolbar-button' onClick={this.changeTemplate}>
                                        Изменить шаблон
                                    </button>
                                    <button className='settings-toolbar-button' onClick={this.reset}>
                                        Сбросить
                                    </button>
                                </div>
                            }
                        </div>
                    </div>
                    <Preview
                        preview={!edit}
                        previewDevice={edit ? 'desktop' : previewDevice}
                        data={data}
                        settings={settings}
                        template={template}>
                        <EditContext.Provider value={edit}>
                            <WrappedComponent {...this.props} sidebarRef={node => this.sidebar = node} />
                        </EditContext.Provider>
                    </Preview>
                </div>
                <SettingsModal
                    visible={showSettings}
                    onCancel={this.closeSettings}
                    title='Настройки'
                    footer={null}
                    destroyOnClose
                    bodyStyle={{ padding: 0 }}
                    closeIcon={<FontAwesomeIcon icon={faTimes} />}
                >
                    <CommonSettingsFields
                        getRef={node => this.settings = node}
                        template={template}
                        settings={settings}
                        params={params}
                        onSave={this.saveSettings}
                        pending={savePending} />
                </SettingsModal>
            </Fragment>;
        }
    }
