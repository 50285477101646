import React from 'react';

import FormatContext from './FormatContext';

export default WrappedComponent => props =>
    <FormatContext.Consumer>
        { ({ formats, format }) =>
            <WrappedComponent {...props} formats={formats} format={format} />
        }
    </FormatContext.Consumer>;
