import React, { Fragment, PureComponent, useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { head } from 'ramda';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import ReactCrop from 'react-image-crop';
import toBlob from 'canvas-to-blob';
import 'react-image-crop/dist/ReactCrop.css';

import FileSettingsContext from '../contexts/FileSettingsContext';
import { StyledModal } from '../formComponents/files/StyledComponents';

const CropImage = ({ image, file, onSave, isBase64 }) => {
    const [ crop, setCrop ] = useState();
    const imgRef = useRef(null);
    const previewCanvasRef = useRef(null);

    const onLoad = useCallback((img) => {
        imgRef.current = img;
    }, []);

    const onComplete = () => {
        if (!crop || !crop.width || !crop.height) {
            onSave(isBase64 ? image : file);
            return;
        }

        if (!previewCanvasRef.current || !imgRef.current) {
            return;
        }

        const img = imgRef.current;
        const canvas = previewCanvasRef.current;

        const scaleX = img.naturalWidth / img.width;
        const scaleY = img.naturalHeight / img.height;
        const ctx = canvas.getContext('2d');
        const pixelRatio = window.devicePixelRatio;

        canvas.width = crop.width * pixelRatio;
        canvas.height = crop.height * pixelRatio;

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(
            img,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        if (isBase64) {
            onSave(canvas.toDataURL('image/png'));
        } else {
            const blob = toBlob(previewCanvasRef.current.toDataURL('image/png'));
            onSave(blob);
        }
    };

    return <Fragment>
        <ReactCrop
            src={image}
            onImageLoaded={onLoad}
            crop={crop}
            onChange={newCrop => setCrop(newCrop)} />
        <canvas
            ref={previewCanvasRef}
            style={{
                width: Math.round(crop?.width ?? 0),
                height: Math.round(crop?.height ?? 0),
                display: 'none'
            }}
        />
        <div>
            <Button style={{ marginTop: 10 }} type='primary' onClick={onComplete}>Сохранить</Button>
        </div>
    </Fragment>;
}

class ImageComponent extends PureComponent {
    static propTypes = {
        value: PropTypes.string,
        onChange: PropTypes.func,
        accept: PropTypes.string
    };

    static defaultProps = {
        accept: 'image/*'
    };

    state = {
        error: false,
        cropModal: false,
        file: null,
        img: null
    };

    openCropModal = e => {
        const file = head(e.target.files);

        if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => this.setState({ cropModal: true, img: reader.result, file });
        }
    }

    closeCropModal = () => this.setState({ cropModal: false, img: null, file: null });

    onChange = e => {
        const file = head(e.target.files);

        if (file) {
            if (this.props.filePostUrl) {
                let formData = new FormData();

                formData.append('file', file);
                fetch(this.props.filePostUrl, { method: 'POST', body: formData })
                    .then(res => res.json())
                    .then(({ id }) => this.props.onChange(this.props.fileGetUrl ? this.props.fileGetUrl.replace(':id', id) : id))
                    .catch(() => {
                        this.setState({ error: true });
                        setTimeout(() => this.setState({ error: false }), 10000);
                    });
            } else {
                const reader = new FileReader();

                reader.readAsDataURL(file);
                reader.onload = () => this.props.onChange(reader.result);
            }
        }
    }

    onSaveCroped = file => {
        if (this.props.filePostUrl) {
            let formData = new FormData();

            formData.append('file', file);
            fetch(this.props.filePostUrl, { method: 'POST', body: formData })
                .then(res => res.json())
                .then(({ id }) => this.props.onChange(this.props.fileGetUrl ? this.props.fileGetUrl.replace(':id', id) : id))
                .catch(() => {
                    this.setState({ error: true });
                    setTimeout(() => this.setState({ error: false }), 10000);
                });
        } else {
            this.props.onChange(file);
        }

        this.closeCropModal();
    }

    remove = () => this.props.onChange(null);

    render() {
        const { value, accept, crop, label, filePostUrl } = this.props;
        const onChange = crop ? this.openCropModal : this.onChange;

        return <Fragment>
            { value ?
                <div className='uploaded-image-container'>
                    <img src={value} alt='preview' />
                    <div className='uploaded-image-edit-btn'>
                        <input type='file' accept={accept} onChange={onChange} />
                        <Button shape='circle' icon={<EditOutlined />} />
                    </div>
                    <Button shape='circle' icon={<DeleteOutlined />} onClick={this.remove} />
                </div> :
                <div className='image-settings-upload'>
                    <div className='image-settings-upload-btn'>
                        <span>Загрузить изображение</span>
                        <input type='file' accept={accept} onChange={onChange} />
                    </div>
                </div>
            }
            { this.state.error &&
                <div
                    style={{
                        position: 'fixed',
                        top: 15,
                        right: 15,
                        fontSize: 14,
                        background: '#fff',
                        fontFamily: 'Arial',
                        padding: 15,
                        zIndex: 1000,
                        boxShadow: '0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05)'
                    }}>
                    Не удалось загрузить файл
                </div>
            }
            <StyledModal
                visible={this.state.cropModal}
                onCancel={this.closeCropModal}
                title={label}
                footer={null}
                destroyOnClose>
                <CropImage image={this.state.img} file={this.state.file} onSave={this.onSaveCroped} isBase64={!filePostUrl} />
            </StyledModal>
        </Fragment>;
    }
}

const Image = props =>
    <FileSettingsContext.Consumer>
        { settings => <ImageComponent {...props} {...settings} /> }
    </FileSettingsContext.Consumer>;

export default Image;
