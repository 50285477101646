import React, { Component } from 'react';
import styled from 'styled-components';
import { Stage, Layer, Rect, Text, Shape } from 'react-konva';
import { pathEq, equals, includes } from 'ramda';

import KonvaImage from './KonvaImage';
import { OG_WIDTH, OG_HEIGHT } from '../../constants/og';
import URLImage from './URLImage';

const Wrapper = styled.div`
    width: 100%;
    zoom: .5;
`;

const Container = styled.div`
    border: 1px solid #dddfe2;
    display: inline-block;
`;

const Description = styled.div`
    border-top: 1px solid #dddfe2;
    background: #EAEAED;
    padding: 15px;
`;

const DescriptionTitle = styled.h4`
`;

const DescriptionText = styled.div`
`;

export default class SocialBanner extends Component {
    state = {
        descriptionY: 410,
        description: null,
        bannerLogo: null,
        backgroundImg: null
    };

    componentDidMount() {
        this.setDescriptionY();
        setTimeout(() => this.textLayer && this.textLayer.draw(), 1000);
        this.getAncorDescription();

        if (this.props.settings.bannerLogo) {
            this.isBase64(this.props.settings.bannerLogo) ?
                this.setState({ bannerLogo: this.props.settings.bannerLogo }) :
                this.toBase64(this.props.settings.bannerLogo, base64 => this.setState({ bannerLogo: base64 }));
        }

        if (this.props.backgroundImg) {
            this.isBase64(this.props.backgroundImg) ?
                this.setState({ backgroundImg: this.props.backgroundImg }) :
                this.toBase64(this.props.backgroundImg, base64 => this.setState({ backgroundImg: base64 }));
        }
    }

    componentDidUpdate(prev) {
        if (prev.settings.bannerTitle !== this.props.settings.bannerTitle) {
            this.setDescriptionY();
        }

        if (this.props.template === 'ancor' && !equals(this.props.settings.bannerAncorDescription, prev.settings.bannerAncorDescription)) {
            this.getAncorDescription();
        }

        if (this.props.settings.bannerLogo !== prev.settings.bannerLogo) {
            !this.props.settings.bannerLogo ? this.setState({ bannerLogo: null }) :
            this.isBase64(this.props.settings.bannerLogo) ? this.setState({ bannerLogo: this.props.settings.bannerLogo }) :
            this.toBase64(this.props.settings.bannerLogo, base64 => this.setState({ bannerLogo: base64 }));
        }

        if (this.props.backgroundImg !== prev.backgroundImg) {
            !this.props.backgroundImg ? this.setState({ backgroundImg: null }) :
            this.isBase64(this.props.backgroundImg) ? this.setState({ backgroundImg: this.props.backgroundImg }) :
            this.toBase64(this.props.backgroundImg, base64 => this.setState({ backgroundImg: base64 }));
        }
    }

    isBase64 = img => includes('base64', img);

    toBase64 = (src, fn) => {
        const image = new Image();
        image.src = src;
        image.setAttribute('crossorigin', 'anonymous');
        image.onload = () => {
            const width = image.width;
            const height = image.height;

            const canvas = document.createElement('canvas');
            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(image, 0, 0);
            const base64 = canvas.toDataURL({format: "png"});
            fn(base64);
        }
    };

    setDescriptionY = () => {
        this.setState({ descriptionY: pathEq(['textArr', 'length'], 1, this.title) ? 410 : 510 });
    }

    getAncorDescription = () => {
        const data =  '<svg xmlns="http://www.w3.org/2000/svg" width="900" height="430">' +
            '<foreignObject width="100%" height="100%">' +
            '<div xmlns="http://www.w3.org/1999/xhtml" style="color: #000; font-size: 30px; font-family: Arial;">' +
            '<style>ul, ol {margin-top: 5px; margin-bottom: 5px;} p {margin: 0;}</style>' +
            (this.props.settings.bannerAncorDescription || '')
                .replace(/<br>/gi, '<div style="height: 20px;" />') +
            '</div></foreignObject></svg>';
        const svg = new Blob([data], {type: 'image/svg+xml;charset=utf-8'});
        const reader = new FileReader();

        reader.readAsDataURL(svg);
        reader.onload = e => {
            this.setState({ description: e.target.result });
        }

    }

    renderAncorBanner = () => {
        const { getRef, settings } = this.props;

        return <Wrapper>
            <Stage width={OG_WIDTH} height={OG_HEIGHT} ref={getRef}>
                <Layer>
                    <Rect
                        width={OG_WIDTH}
                        height={OG_HEIGHT}
                        fill='#fff'
                    />
                </Layer>
                <Layer>
                    <Shape
                        sceneFunc={(context, shape) => {
                            context.beginPath();
                            context.moveTo(0, 0);
                            context.lineTo(1100, 0);
                            context.lineTo(1000, 150);
                            context.lineTo(0, 150);
                            context.closePath();
                            context.fillStrokeShape(shape);
                        }}
                        fill='#2b3d4f' />
                </Layer>
                <Layer>
                    <Shape
                        sceneFunc={(context, shape) => {
                            context.beginPath();
                            context.moveTo(1000, 150);
                            context.lineTo(1200, 150);
                            context.lineTo(1200, 630);
                            context.lineTo(690, 630);
                            context.closePath();
                            context.fillStrokeShape(shape);
                        }}
                        fill='#f54d2e' />
                </Layer>
                { settings.bannerLogo &&
                    <Layer x={930} y={380}>
                        <KonvaImage
                            image={settings.bannerLogo}
                            width={180}
                            height={180} />
                    </Layer>
                }
                <Layer>
                    <Text
                        ref={node => this.title = node}
                        text={settings.bannerTitle || 'Заголовок'}
                        fontSize={54}
                        fill='#fff'
                        opacity={settings.bannerTitle ? 1 : .5}
                        width={OG_WIDTH - 230}
                        height={150}
                        y={0}
                        x={20}
                        verticalAlign='middle' />
                </Layer>
                <Layer>
                    <URLImage
                        x={30}
                        y={180}
                        src={this.state.description} />
                </Layer>
            </Stage>
        </Wrapper>;
    }

    renderBanner = () => {
        const { settings, background, getRef } = this.props;
        const { bannerLogo, backgroundImg } = this.state;

        return <Wrapper innerRef={node => this.container = node}>
            <Stage width={OG_WIDTH} height={OG_HEIGHT} ref={getRef}>
                <Layer>
                    <Rect
                        width={OG_WIDTH}
                        height={OG_HEIGHT}
                        fill={background}
                    />
                </Layer>
                { backgroundImg &&
                    <Layer>
                        <KonvaImage
                            image={backgroundImg} />
                    </Layer>
                }
                <Layer>
                    <Rect
                        width={OG_WIDTH}
                        height={OG_HEIGHT}
                        fill={settings.bannerLightTheme ? '#eee' : '#222'}
                        opacity={.7}
                    />
                </Layer>
                { bannerLogo &&
                    <Layer x={875} y={50}>
                        <KonvaImage
                            image={bannerLogo}
                            width={200}
                            height={200} />
                    </Layer>
                }
                <Layer ref={node => this.textLayer = node}>
                    <Text
                        ref={node => this.title = node}
                        text={settings.bannerTitle || 'Заголовок'}
                        fontSize={80}
                        fill={settings.bannerLightTheme ? '#000' : '#fff'}
                        opacity={settings.bannerTitle ? 1 : .5}
                        width={OG_WIDTH - 230}
                        height={200}
                        y={315}
                        x={115} />
                    <Text
                        ref={node => this.description = node}
                        text={
                            (settings.bannerCity ? `\uf041 ${settings.bannerCity}` : '') +
                            (settings.bannerExperience ? `${settings.bannerCity ? '   ' : ''}\uf017 ${settings.bannerExperience}` : '') +
                            (settings.bannerSalary ? `${settings.bannerCity || settings.bannerExperience ? '   ' : ''}\uf158 ${settings.bannerSalary}` : '')
                        }
                        fill={settings.bannerLightTheme ? '#000' : '#fff'}
                        fontSize={30}
                        width={OG_WIDTH - 230}
                        fontFamily='FontAwesome, sans-serif'
                        x={115}
                        y={this.state.descriptionY} />
                </Layer>
                <Layer>
                </Layer>
            </Stage>
        </Wrapper>;
    }

    render() {
        const { template, settings } = this.props;

        return <Container>
            { template === 'ancor' ? this.renderAncorBanner() : this.renderBanner() }
            { (settings.bannerTitle || settings.bannerDescription) &&
                <Description>
                    <DescriptionTitle>{ settings.bannerTitle }</DescriptionTitle>
                    <DescriptionText>{ settings.bannerDescription }</DescriptionText>
                </Description>
            }
        </Container>;
    }
}
