import React, { Component } from 'react';
import AceEditor from 'react-ace';
import { contains } from 'ramda';

import 'brace/mode/javascript';
import 'brace/theme/tomorrow';
import 'brace/ext/searchbox';

const defaultScript = 'function onSubmit(values) {\n\n}';
const { Range } = window.ace.acequire("ace/range");

function before(obj, method, wrapper) {
    var orig = obj[method];
    obj[method] = function() {
        var args = Array.prototype.slice.call(arguments);
        return wrapper.call(this, function(){
            return orig.apply(obj, args);
        }, args);
    }

    return obj[method];
}

const isCtrlBtn = event => event && (event.ctrlKey || contains(event.which, [91, 93]));

export default class ScriptEditor extends Component {
    constructor(props) {
        super(props);

        this.rangeStart = new Range(0, 0, 0, 27);
        this.rangeEnd = new Range(props.endRow || 2, 0, props.endRow || 2, 1);
        this.ctrl = false;
    }

    componentDidMount() {
        const { editor } = this.editor;

        const session = editor.getSession();

        session.addMarker(this.rangeStart, 'readonly-highlight');
        session.addMarker(this.rangeEnd, 'readonly-highlight');

        document.addEventListener('keydown', this.downCtrl);
        document.addEventListener('keyup', this.upCtrl);

        editor.keyBinding.addKeyboardHandler({
            handleKeyboard: (data, hash, keyString, keyCode, event) => {
                const cursor = editor.selection.getCursor();
                const rangeEndPosEnd = this.rangeEnd.end.getPosition();
                const rangeEndPosStart = this.rangeEnd.start.getPosition();
                const rangeStartPosEnd = this.rangeStart.end.getPosition();

                if (isCtrlBtn(event) || this.ctrl) {
                    return { command: "null", passEvent: true };
                }

                if (
                    hash === -1 ||
                    ((keyCode <= 40 && keyCode >= 37)) ||
                    (keyCode === 13 && (
                        (rangeEndPosEnd.row === cursor.row && rangeEndPosEnd.column === cursor.column) ||
                        (rangeEndPosStart.row === cursor.row && rangeEndPosStart.column === cursor.column)
                    )) ||
                    (keyCode === 13 && (rangeStartPosEnd.row === cursor.row && rangeStartPosEnd.column === cursor.column))
                ) return false;

                if (this.intersects(this.rangeStart) || this.intersects(this.rangeEnd)) {
                    return { command: "null", passEvent: false };
                }
            }
        });

        before(editor, 'onPaste', this.preventReadonly);
        before(editor, 'onCut', this.preventReadonly);

        this.rangeStart.start = session.doc.createAnchor(this.rangeStart.start);
        this.rangeStart.end = session.doc.createAnchor(this.rangeStart.end);
        this.rangeStart.end.$insertRight = true;

        this.rangeEnd.start = session.doc.createAnchor(this.rangeEnd.start);
        this.rangeEnd.end = session.doc.createAnchor(this.rangeEnd.end);
        this.rangeEnd.end.$insertRight = true;
        this.rangeEnd.end.on('change', ({ value }) => {
            this.props.onChangeEndRow(value.row);
        });
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.downCtrl);
        document.removeEventListener('keyup', this.upCtrl);
    }

    downCtrl = event => {
        if (isCtrlBtn(event)) {
            this.ctrl = true;
        }
    }

    upCtrl = event => {
        if (isCtrlBtn(event)) {
            this.ctrl = false;
        }
    }

    intersects(range) {
        return this.editor.editor.getSelectionRange().intersects(range);
    }

    preventReadonly = (next, args) => {
        if (this.intersects(this.rangeStart) || this.intersects(this.rangeEnd)) {
            return;
        }

        next();
    }

    onValidate = errors => this.props.onValidate(!errors.length);

    render() {
        const { script, onChange } = this.props;

        return <AceEditor
            ref={node => this.editor = node}
            mode='javascript'
            theme='tomorrow'
            width='100%'
            value={script || defaultScript}
            onChange={onChange}
            name='js-editor'
            editorProps={{ $blockScrolling: true }}
            onValidate={this.onValidate}
            showPrintMargin={false} />;
    }
}
