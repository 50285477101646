import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { find, propEq, path, findIndex } from 'ramda';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Collapsed from './Collapsed';

class Select extends Component {
    static propTypes = {
        type: PropTypes.string,
        options: PropTypes.array,
        icon: PropTypes.object,
        allowScroll: PropTypes.bool
    };

    getSelectedIcon = value => {
        const { options } = this.props;

        return path(['icon'], find(propEq('value', value), options));
    }

    getButton = value => {
        const selectedIcon = this.getSelectedIcon(value) || this.props.icon;

        return selectedIcon && <FontAwesomeIcon icon={selectedIcon} />;
    }

    getScrollTo = value => {
        const index = findIndex(propEq('value', value), this.props.options);

        return index < 0 ? 0 : 42 * index;
    }

    render() {
        const { type, options, allowScroll } = this.props;

        return <Collapsed
            type={type}
            className='ql-select'
            button={this.getButton}
            allowScroll={allowScroll}
            scrollTo={this.getScrollTo}>
            { ({ value, onSelect, close }) => options.map(option =>
                <button
                    className={cx({ selected: option.value === value, text: option.text })}
                    key={`button-${type}-${option.value}`}
                    onClick={() => {
                        onSelect(option.value);
                        close();
                    }}>
                    { option.text || <FontAwesomeIcon icon={option.icon} /> }
                </button>
            )}
        </Collapsed>;
    }
}

export default Select;
