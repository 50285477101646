import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cx from 'classnames';
import { pick, find, propEq } from 'ramda';

import { DEVICES } from '../constants/devices';
import IFrame from './IFrame';

const calcZoom = ({ width, height, currentWidth, currentHeight }) => {
    const horizontalZoom = currentWidth < width ? currentWidth / width : 1;
    const verticalZoom = currentHeight - 90 < height ? (currentHeight - 90) / height : 1;
    const zoom = (horizontalZoom >= 1 && verticalZoom >= 1) ? 1 : horizontalZoom > verticalZoom ? verticalZoom : horizontalZoom;

    return !width && !height ? 1 : zoom;
};

const PreviewContainer = styled.div`
    width: ${({ width }) => width ? `${width}px` : '100%'};
    height: ${({ height }) => height ? `${height}px` : '100%'};
    zoom: ${calcZoom};
`;

export default class Preview extends Component {
    static propTypes = {
        preview: PropTypes.bool,
        previewDevice: PropTypes.string,
        children: PropTypes.node,
        data: PropTypes.object,
        settings: PropTypes.object,
        template: PropTypes.string
    };

    static defaultProps = {
        previewDevice: 'desktop'
    };

    constructor() {
        super();

        this.state = {
            clientHeight: null,
            clientWidth: null
        };
    }

    componentDidMount() {
        this.updateDimensions();

        window.addEventListener('resize', this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    updateDimensions = () => {
        this.setState({
            clientHeight: document.body.clientHeight,
            clientWidth: document.body.clientWidth
        });
    }

    getPreviewSize() {
        const { previewDevice } = this.props;

        return pick(['width', 'height'], find(propEq('value', previewDevice), DEVICES));
    }

    render() {
        const { preview, previewDevice, data, settings, template } = this.props;
        const { clientHeight, clientWidth } = this.state;

        return <PreviewContainer
            className={cx(previewDevice, { 'preview-mode': preview, 'edit-mode': !preview })}
            {...this.getPreviewSize()}
            currentWidth={clientWidth}
            currentHeight={clientHeight}>
            { previewDevice !== 'desktop' ?
                <IFrame data={data} settings={settings} template={template} /> :
                this.props.children
            }
        </PreviewContainer>;
    }
}
