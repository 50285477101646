import React, { Component } from 'react';
import PropTypes from 'prop-types';

import DataContext from '../context/DataContext';
import EditContext from '../context/EditContext';
import Quill from './quill/Quill';

export default class EditBlock extends Component {
    static propTypes = {
        html: PropTypes.string,
        name: PropTypes.string,
        allowLists: PropTypes.bool,
        allowColor: PropTypes.bool
    };

    static defaultProps = {
        html: '',
        allowColor: true,
        allowFontSize: true
    };

    render() {
        const { allowLists, html, allowColor, allowFontSize, name } = this.props;

        return <EditContext.Consumer>
            { edit => edit ?
                <DataContext.Consumer>
                    { ({ onChange }) =>
                        <Quill
                            onChange={onChange}
                            html={html}
                            name={name}
                            allowColor={allowColor}
                            allowLists={allowLists}
                            allowFontSize={allowFontSize} />
                    }
                </DataContext.Consumer> :
                <div className='html-block' dangerouslySetInnerHTML={{ __html: html }} />
            }
        </EditContext.Consumer>;
    }
}
