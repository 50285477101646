import React, { Component, Fragment } from 'react';
import { Tabs, Button } from 'antd';
import { assoc, propEq, last, equals } from 'ramda';
import styled, { css } from 'styled-components';
import AceEditor from 'react-ace';

import { SETTINGS } from '../../constants/settings';
import SocialBanner from '../landingParts/SocialBanner';
import ScriptEditor from './ScriptEditor';

import 'brace/mode/css';
import 'brace/theme/tomorrow';
import 'brace/ext/searchbox';

const Title = styled.p`
    font-size: 20px;
    margin-bottom: 10px;
`;

const BannerWrapper = styled.div`
    margin-bottom: 15px;
    margin-left: 15px;
    display: inline-block;
    vertical-align: top;
`;

const TabWrapper = styled.div`
    padding: 15px;
`;

const BannerTabWrapper = styled(TabWrapper)`
`;

const BannerFieldsWrapper = styled.div`
    width: calc(100% - 620px);
    display: inline-block;
    @media (max-width: 900px) {
        width: 100%;
    }
`;

const BannerTogglerWrapper = styled.div`
    padding: 15px 15px 0;

    ${({ border }) => border && css`
        .setting-group {
            border-bottom: 1px dashed #e4e3e3 !important;
        }
    `}
`;

const StyledTabs = styled(Tabs)`
    .ant-tabs-bar {
        margin: 0;
    }
    .ant-tabs-nav-wrap {
        padding-left: 25px;
        padding-right: 25px;
    }
`;

const ScriptTab = styled.span`
    ${({ invalid }) => invalid && css`
        color: red;
    `}
`;

export default class CommonSettingsFields extends Component {
    constructor(props) {
        super(props);

        this.state = {
            settings: props.settings.common || {},
            validScript: true,
            activeTab: 'fields'
        };
    }

    componentDidMount() {
        this.props.getRef(this);
    }

    save = () => {
        this.props.onSave({
            ...this.state.settings,
            ogImage: this.state.activeTab === 'banner' ? (this.banner ? last(this.banner.toDataURL().split('base64,')) : '') : this.state.settings.ogImage
        });
    }

    onChange = (value, name) => {
        this.setState(prev => ({
            settings: assoc(name, value, prev.settings)
        }));
    }

    renderFields = group => {
        const { template, params } = this.props;
        const { settings } = this.state;
        const fields = SETTINGS[template].common.filter(propEq('group', group));

        return fields.map((field, index) => {
            const { label, component, name, hideLabel, isHidden, options, title } = field;
            const Component = component;

            return isHidden && isHidden(settings, this.props) ? null :
                <div className='setting-group' key={`setting-${index}`}>
                    { title && <Title>{ title }</Title> }
                    { !hideLabel && <p className='settings-label'>{ label }</p> }
                    <Component
                        onChange={value => this.onChange(value, name)}
                        onChangeValue={this.onChange}
                        value={settings[name]}
                        name={name}
                        settings={settings}
                        params={params}
                        options={options}
                        label={label} />
                </div>;
        })
    }

    onChangeStyles = styles => this.onChange(styles, 'styles');

    onChangeScript = script => this.onChange(script, 'script');

    onChangeScriptValidation = validScript => {
        if (validScript !== this.state.validScript) {
            this.setState({ validScript });
        }
    }

    onChangeEndScriptRow = endScriptRow => this.onChange(endScriptRow, 'endScriptRow');

    isDirty = () => {
        return !equals(this.props.settings.common, this.state.settings);
    }

    onChangeTab = activeTab => {
        this.setState({ activeTab });
    }

    render() {
        const { settings: { header }, pending, template } = this.props;
        const { banner, styles, script, endScriptRow } = this.state.settings;

        return (
            <Fragment>
                <StyledTabs defaultActiveKey='fields' activeKey={this.state.activeTab} onChange={this.onChangeTab}>
                    <Tabs.TabPane tab='Общие' key='fields'>
                        <TabWrapper>{ this.renderFields() }</TabWrapper>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab='Баннер для социальных сетей' key='banner'>
                        <BannerTogglerWrapper border={banner}>
                            { this.renderFields('banner-switch') }
                        </BannerTogglerWrapper>
                        <BannerTabWrapper>
                            <BannerFieldsWrapper>{ this.renderFields('banner') }</BannerFieldsWrapper>
                            { banner && (
                                <BannerWrapper>
                                    <SocialBanner
                                        settings={this.state.settings}
                                        backgroundImg={header.backgroundImage}
                                        background={header.background}
                                        getRef={node => this.banner = node}
                                        template={template} />
                                </BannerWrapper>
                            )}
                        </BannerTabWrapper>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab='Стили' key='styles'>
                        <AceEditor
                            mode='css'
                            theme='tomorrow'
                            width='100%'
                            value={styles}
                            onChange={this.onChangeStyles}
                            name='css-editor'
                            editorProps={{ $blockScrolling: true }}
                            showPrintMargin={false} />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={<ScriptTab invalid={!this.state.validScript}>Скрипт</ScriptTab>} key='script'>
                        <ScriptEditor
                            script={script}
                            endRow={endScriptRow}
                            onChange={this.onChangeScript}
                            onChangeEndRow={this.onChangeEndScriptRow}
                            onValidate={this.onChangeScriptValidation} />
                    </Tabs.TabPane>
                </StyledTabs>
                <div className='ant-modal-footer'>
                    <Button type='primary' onClick={this.save} disabled={pending || !this.state.validScript}>
                        Сохранить
                    </Button>
                </div>
            </Fragment>
        );
    }
}
