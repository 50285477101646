import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { find, propEq, prop, findIndex, last, head } from 'ramda';
import { Modal, Button, Spin } from 'antd';
import cx from 'classnames';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

import PreviewTemplate from './PreviewTemplate';
import { THEMES } from '../constants/templates';

export default class PreviewTemplateModal extends Component {
    static propTypes = {
        template: PropTypes.string,
        data: PropTypes.object,
        settings: PropTypes.object,
        onClose: PropTypes.func,
        onSelect: PropTypes.func,
        onSetTheme: PropTypes.func,
        onChangePreview: PropTypes.func,
        templates: PropTypes.array,
        isAncor: PropTypes.bool,
        themeLoading: PropTypes.bool,
    };

    onChangeTemplate = forward => {
        const { template, onChangePreview, templates } = this.props;
        const current = findIndex(propEq('value', template), templates);
        const replace = forward ?
            (current >= templates.length - 1 ? head(templates) : templates[current + 1]).value :
            (current > 0 ? templates[current - 1] : last(templates)).value;

        onChangePreview(replace);
    }

    render() {
        const { template, theme, onClose, onSelect, onSetTheme, onChangePreview, data, settings, templates, isAncor, themeLoading } = this.props;
        const title = prop('label', find(propEq('value', template), templates));

        return <Modal
            className='modal-template'
            visible={!!template}
            footer={null}
            onCancel={onClose}
            width="100%"
        >
            { template &&
                <div className='modal-template-body'>
                    <div className='modal-template-body-preview'>
                        <Spin spinning={themeLoading} className='theme-spinner' delay={300}>
                            <PreviewTemplate template={template} data={data} settings={settings} isAncor={isAncor} />
                        </Spin>
                    </div>
                    <div className='modal-template-body-desc'>
                        <div className='controls'>
                            <Button shape='circle' icon={<LeftOutlined />} onClick={() => this.onChangeTemplate()} />
                            <Button shape='circle' icon={<RightOutlined />} onClick={() => this.onChangeTemplate(true)} />
                        </div>
                        <div className='themes-select'>
                            { THEMES[template].map((themeItem) => {
                                return (
                                    <div
                                        key={themeItem}
                                        className={cx(`theme-img theme-img-${template} theme-img-${themeItem}`, {
                                            selected: themeItem === (theme || 'default') ,
                                        })}
                                        onClick={() => {
                                            onSetTheme(template, themeItem);
                                            onChangePreview(template, themeItem);
                                        }}
                                    />
                                );
                            })}
                        </div>
                        <button className='button button-lg' title={title} onClick={onSelect}>Выбрать</button>
                    </div>
                </div>
            }
        </Modal>;
    }
}
