import { THEMES } from '../constants/templates';

export const getThemesTitle = (template) => {
    const themesLength = THEMES[template].length;

    if (themesLength === 1) {
        return '1 вариант темы';
    } else if (themesLength > 1 && themesLength <= 4) {
        return `${themesLength} варианта тем`;
    } else {
        return `${themesLength} вариантов тем`;
    }
};
