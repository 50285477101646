import './utils/iepolyfill';

import React from 'react';
import { render } from 'react-dom';

import Builder from './components/BuilderRoute';

import { provideInterceptors, setLanguage } from './utils/http';
import rollbarInit from './utils/rollbarInit';

import 'antd/dist/antd.css';
import 'react-quill/dist/quill.bubble.css';
import 'react-custom-scroll/dist/customScroll.css';
import './styles/index.sass';

require('dotenv').config();

if (process.env.NODE_ENV === 'production') {
    rollbarInit({
        token: '8f47b71c320b4e5d907f062cd3eba1fc',
        version: process.env.REACT_APP_VERSION,
        environment: window.location.host
    });

    window._version = {
        version: process.env.REACT_APP_VERSION
    };
}

const MOUNT_NODE = document.getElementById('root');

provideInterceptors();
setLanguage();

render(<Builder />, MOUNT_NODE);
