import {
    faAlignRight,
    faAlignLeft,
    faAlignJustify,
    faAlignCenter
} from '@fortawesome/free-solid-svg-icons';
import { range } from 'ramda';

export const FORMATS = [
    'header',
    'bold', 'italic', 'underline', 'strike',
    'size',
    'list', 'bullet',
    'color', 'custom-background',
    'align',
    'custom-link'
];

export const FORMATS_WITHOUT_LISTS = [
    'header',
    'bold', 'italic', 'underline', 'strike',
    'size',
    'color', 'custom-background',
    'align',
    'custom-link'
];

export const FORMATS_WITHOUT_COLOR = [
    'header',
    'bold', 'italic', 'underline', 'strike',
    'size',
    'align',
    'custom-link'
];

export const FORMATS_WITHOUT_SIZE = [
    'header',
    'bold', 'italic', 'underline', 'strike',
    'list', 'bullet',
    'color', 'custom-background',
    'align',
    'custom-link'
];

export const FORMATS_WITHOUT_LISTS_AND_SIZE = [
    'header',
    'bold', 'italic', 'underline', 'strike',
    'color', 'custom-background',
    'align',
    'custom-link'
];

export const FORMATS_WITHOUT_COLOR_AND_SIZE = [
    'header',
    'bold', 'italic', 'underline', 'strike',
    'align',
    'custom-link'
];

export const ALIGN = [
    { value: '', icon: faAlignLeft },
    { value: 'center', icon: faAlignCenter },
    { value: 'right', icon: faAlignRight },
    { value: 'justify', icon: faAlignJustify }
];

export const PARAGRAPH = [
    { value: 1, text: 'Header 1' },
    { value: 2, text: 'Header 2' },
    { value: 3, text: 'Header 3' },
    { value: 4, text: 'Header 4' },
    { value: 5, text: 'Header 5' },
    { value: 6, text: 'Header 6' },
    { value: '', text: 'Normal' }
];

export const SIZE = [
    { value: '', text: 'по умолчанию' },
    ...range(8, 73).filter(n => n % 2 === 0).map(size => ({
        value: `${size}px`, text: size
    }))
];

export const PRESET_COLORS = [
    '#D0021B',
    '#F5A623',
    '#F8E71C',
    '#8B572A',
    '#7ED321',
    '#417505',
    '#BD10E0',
    '#9013FE',
    '#4A90E2',
    '#50E3C2',
    '#B8E986',
    '#000000',
    '#4A4A4A',
    '#9B9B9B',
    '#FFFFFF',
    'transparent'
];

export const getFormats = (color, lists, size) => {
    return color ?
        (size ? FORMATS : FORMATS_WITHOUT_SIZE) : lists ?
        (size ? FORMATS_WITHOUT_LISTS : FORMATS_WITHOUT_LISTS_AND_SIZE) :
        (size ? FORMATS_WITHOUT_COLOR : FORMATS_WITHOUT_COLOR_AND_SIZE);
};
