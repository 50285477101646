import React, { Component } from 'react';
import CustomScroll from 'react-custom-scroll';

import { pushRollbarError } from '../utils/rollbarInit';
import LandingBuilder from './LandingBuilder';

export default class PreviewRoute extends Component {
    state = {
        error: false,
        data: null,
    };

    componentDidCatch(error, info) {
        this.setState({ error: true });
        pushRollbarError(error, info);
    }

    componentDidMount() {
        window.addEventListener('message', this.receiveData);
    }

    componentWillUnmount() {
        window.removeEventListener('message', this.receiveData);
    }

    receiveData = ({ data }) => this.setState({ data });

    render() {
        const { data, error } = this.state;

        if (error) {
            return 'Не удалось отобразить данные';
        }

        return data &&
            <CustomScroll
                allowOuterScroll
                heightRelativeToParent='100%'>
                <div className='custom-scroll-content'>
                    <LandingBuilder
                        template={data.template}
                        data={data.data}
                        settings={data.settings} />
                </div>
            </CustomScroll>;
    }
}
