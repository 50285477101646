import React, { Component } from 'react';
import { Quill } from 'react-quill';
import { range } from 'ramda';

import { pushRollbarError } from '../utils/rollbarInit';
import withSettingsState from './hocs/withSettingsState';
import withTemplate from './hocs/withTemplate';
import withToolbar from './hocs/withToolbar';
import withData from './hocs/withData';
import withToolbarContextProvider from './quill/withToolbarContextProvider';
import BuilderComponent from './BuilderComponent';
import CustomLink from './quill/customAttrs/Link';
import CustomBackground from './quill/customAttrs/Background';

const SizeStyle = Quill.import('attributors/style/size');
SizeStyle.whitelist = range(8, 73).filter(n => n % 2 === 0).map(size => `${size}px`);

Quill.register(SizeStyle, true);
Quill.register(CustomLink);
Quill.register(CustomBackground);

class Builder extends Component {
    state = {
        error: false,
    }

    handleDocumentClick = e => {
        this.props.setToolbar(null);
    }

    componentDidCatch(error, info) {
        this.setState({ error: true });
        pushRollbarError(error, info);
    }

    render() {
        if (this.state.error) {
            return 'Не удалось запустить приложение';
        }

        return <div onMouseDown={this.handleDocumentClick}>
            <BuilderComponent {...this.props} isBuilder />
        </div>;
    }
}

export default withTemplate(
    withToolbar(
        withData(
            withSettingsState(
                withToolbarContextProvider(Builder)
            )
        )
    )
);
