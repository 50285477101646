import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { prop } from 'ramda';

import { BULLETS } from '../../constants/bullets';

const StyledBullet = styled.button`
    color: ${prop('color')}
`;

export default class Bullet extends PureComponent {
    static propTypes = {
        value: PropTypes.string,
        settings: PropTypes.object,
        onChange: PropTypes.func
    };

    state = {
        opened: false
    };

    onToggle = () => this.setState(prev => ({ opened: !prev.opened }));

    onClose = () => this.setState({ opened: false });

    onChange = bullet => {
        this.props.onChange(bullet);
        this.onClose();
    }

    render() {
        const { value, settings: { bulletColor }} = this.props;
        const { opened } = this.state;

        return <div className='bullet-settings'>
            { opened && <div className='cover' onClick={this.onClose} /> }
            <StyledBullet
                className={`bullet-settings-toggle icon-${value}`}
                onClick={this.onToggle}
                color={bulletColor} />
            { opened &&
                <div className='bullet-settings-options'>
                    { BULLETS.map((bullet, index) =>
                        <StyledBullet
                            key={`bullet-option-${index}`}
                            className={`icon-${bullet}`}
                            onClick={() => this.onChange(bullet)}
                            color={bulletColor} />
                    )}
                </div>
            }
        </div>;
    }
}
