import React, { Component } from 'react';
import PropTypes from 'prop-types';

import EditContext from '../../context/EditContext';
import SettingsToolbar from '../settingsFields/SettingsToolbar';

export default WrappedComponent =>
    class extends Component {
        static propTypes = {
            template: PropTypes.string,
            settings: PropTypes.object
        };

        static defaultProps = {
            template: 'default',
            settings: {}
        };

        state = {
            opened: null
        };

        componentDidMount() {
            const { sidebarRef } = this.props;

            sidebarRef && sidebarRef(this);
        }

        onToggle = (key, options) => this.setState(prev => {
            const opened = key !== prev.opened ? key : null ;

            return {
                opened,
                options: opened ? options : null
            };
        });

        onClose = () => this.setState({ opened: null, options: null });

        render() {
            const { opened, options } = this.state;
            const { onSettingsChange, settings, template, params } = this.props;

            return <div>
                <EditContext.Consumer>
                    { edit => edit ?
                        <SettingsToolbar
                            opened={opened}
                            onSettingsChange={onSettingsChange}
                            settings={settings}
                            template={template}
                            onClose={this.onClose}
                            params={params}
                            options={options} /> : null
                    }
                </EditContext.Consumer>
                <WrappedComponent
                    {...this.props}
                    onSettingsToggle={this.onToggle}
                    opened={opened}
                />
            </div>
        }
    }
