import React from 'react';
import { Switch, Route, BrowserRouter as Router, Redirect } from 'react-router-dom';

import Builder from './Builder';
import AncorBuilder from './AncorBuilder';
import PreviewRoute from './PreviewRoute';

export default () =>
    <Router>
        <Switch>
            <Route exact path='/' component={Builder} />
            <Route path='/ancor' component={AncorBuilder} />
            <Route path='/preview' component={PreviewRoute} />
            <Route component={() => <Redirect to='/' />} />
        </Switch>
    </Router>;
