import defaultTemplate from './templates/default';
import blueTemplate from './templates/blue';
import redTemplate from './templates/red';
import grayTemplate from './templates/gray';
import greenTemplate from './templates/green';
import limeTemplate from './templates/lime';
import ancorTemplate from './templates/ancor';
import icebergTemplate from './templates/iceberg';

export const HTML = {
    default: defaultTemplate,
    red: redTemplate,
    green: greenTemplate,
    blue: blueTemplate,
    gray: grayTemplate,
    lime: limeTemplate,
    ancor: ancorTemplate,
    iceberg: icebergTemplate
};

export const getHTML = template => (
    HTML[template]
);
