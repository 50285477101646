import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Switch } from 'antd';

const Container = styled.div`
    position: relative;
    float: right;
    margin-bottom: 10px;
`;

export default class SwitchComponent extends PureComponent {
    static propTypes = {
        settings: PropTypes.object,
        value: PropTypes.bool,
        onChange: PropTypes.func
    };

    static defaultProps = {
        options: [],
    };

     onChange = checked => this.props.onChange(checked);

    render() {
        const { value, disabled } = this.props;

        return <Container>
            <Switch
                disabled={disabled}
                className='radio-box'
                onChange={this.onChange}
                checked={value}
            />
        </Container>;
    }
}
