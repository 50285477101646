import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faBold,
    faItalic,
    faUnderline,
    faStrikethrough,
    faPalette,
    faFill,
    faEraser,
    faParagraph,
    faTextHeight,
    faListOl,
    faListUl,
    faAlignLeft
} from '@fortawesome/free-solid-svg-icons';

import Select from './Select';
import Color from './Color';
import Link from './Link';
import { ALIGN, SIZE, PARAGRAPH } from '../../constants/quill';

export default class Toolbar extends Component {
    static propTypes = {
        id: PropTypes.string,
        show: PropTypes.bool,
        allowColor: PropTypes.bool,
        allowLists: PropTypes.bool,
        allowFontSize: PropTypes.bool
    };

    onMouseDown = e => {
        if (!(e.target instanceof HTMLInputElement)) {
            e.preventDefault();
        }

        e.stopPropagation();
    }

    render() {
        const { id, show, allowColor, allowLists, allowFontSize } = this.props;

        return <div id={id} className={cx('ql-toolbar', { show })}>
            <div className='ql-toolbar-wrapper' onMouseDown={this.onMouseDown}>
                <Select
                    type='header'
                    icon={faParagraph}
                    options={PARAGRAPH} />
                { allowFontSize &&
                    <Select
                        type='size'
                        icon={faTextHeight}
                        allowScroll
                        options={SIZE} />
                }
                <button className='ql-bold'>
                    <FontAwesomeIcon icon={faBold} />
                </button>
                <button className='ql-italic'>
                    <FontAwesomeIcon icon={faItalic} />
                </button>
                <button className='ql-underline'>
                    <FontAwesomeIcon icon={faUnderline} />
                </button>
                <button className='ql-strike'>
                    <FontAwesomeIcon icon={faStrikethrough} />
                </button>
                <Select type='align' options={ALIGN} icon={faAlignLeft} />
                { allowLists &&
                    <Fragment>
                        <button className='ql-list' value='ordered'>
                            <FontAwesomeIcon icon={faListOl} />
                        </button>
                        <button className='ql-list' value='bullet'>
                            <FontAwesomeIcon icon={faListUl} />
                        </button>
                    </Fragment>
                }
                { allowColor &&
                    <Fragment>
                        <Color type='color' icon={faPalette} />
                        <Color type='custom-background' icon={faFill} />
                    </Fragment>
                }
                <Link type='custom-link' />
                <button className='ql-clean'>
                    <FontAwesomeIcon icon={faEraser} />
                </button>
            </div>
        </div>
    }
}
