import { propEq, pathOr } from 'ramda';

import Text from '../components/settingsFields/Text';
import ColorPicker from '../components/settingsFields/ColorPicker';
import Image from '../components/settingsFields/Image';
import Bullet from '../components/settingsFields/Bullet';
import Checkbox from '../components/settingsFields/Checkbox';
import Switch from '../components/settingsFields/Switch';
import GrayMainModePicker from '../components/settingsFields/GrayMainModePicker';
import FaviconLoader from '../components/settingsFields/FaviconLoader';
import BotLink from '../components/settingsFields/BotLink';

import {
    redBg,
    greenBg,
    blueBg,
    bluePhoto,
    ancorBg,
    grayBg,
    limeBg
} from './images';
import SelectRows from '../components/settingsFields/SelectRows';
import VacancySelector from '../components/settingsFields/VacancySelector';
import BackgroundPosition from '../components/settingsFields/BackgroundPosition';
import Textarea from '../components/settingsFields/Textarea';
import Editor from '../components/settingsFields/Editor';
import SelectElementsAmount from '../components/settingsFields/SelectElementsAmount';

const isHiddenLimeVacancy = ({ block }) => !block;
const isHiddenBannerOptions = ({ banner }) => !banner;
const isHiddenVacancySelector = (_, __, params) => !pathOr([], ['vacancies'], params).length;
const isHiddenBackgroundPosition = ({ backgroundImage }) => !backgroundImage;
const isHiddenAncorBannerOptions = (settings, props) => isHiddenBannerOptions(settings) || props.template === 'ancor';

const commonHeaderOptions = [
    { name: 'vacancyLink', value: '', component: BotLink, label: 'Ссылка на чат-ботов Findy' },
    { name: 'headerVacancyLink', value: false, component: Checkbox, label: 'Отображать в шапке', hideLabel: true },
    { name: 'vacancyLinkWidget', value: false, component: Checkbox, label: 'Включить Findy-виджет', hideLabel: true },
    { name: 'pageTitle', value: 'Анкета кандидата', component: Text, label: 'Заголовок страницы' },
    { name: 'formTitle', value: 'Анкета кандидата', component: Text, label: 'Заголовок формы' },
    { name: 'banner', group: 'banner-switch', value: false, component: Switch, label: 'Включить баннер для социальных сетей' },
    { name: 'bannerTitle', group: 'banner', value: '', component: Text, label: 'Заголовок', isHidden: isHiddenBannerOptions },
    { name: 'bannerLogo', group: 'banner', value: null, component: Image, label: 'Логотип', isHidden: isHiddenBannerOptions },
    { name: 'bannerLightTheme', group: 'banner', value: false, component: Checkbox, label: 'Светлая тема', hideLabel: true, isHidden: isHiddenAncorBannerOptions },
    { name: 'bannerCity', group: 'banner', value: '', component: Text, label: 'Город', isHidden: isHiddenAncorBannerOptions },
    { name: 'bannerExperience', group: 'banner', value: '', component: Text, label: 'Опыт', isHidden: isHiddenAncorBannerOptions },
    { name: 'bannerSalary', group: 'banner', value: '', component: Text, label: 'Заработная плата', isHidden: isHiddenAncorBannerOptions },
    { name: 'bannerAncorDescription', group: 'banner', value: '', component: Editor, label: 'Блок описания', isHidden: (settings, props) => isHiddenBannerOptions(settings) || props.template !== 'ancor' },
    { name: 'bannerDescription', group: 'banner', value: '', component: Textarea, label: 'Описание', isHidden: isHiddenBannerOptions }
];

const logoSettings = [
    { name: 'logo', value: null, component: Image, label: 'Логотип' },
    { name: 'logoPosition', value: 'center', component: BackgroundPosition, label: 'Позиционирование логотипа', isHidden: ({ logo }) => !logo, props: { horizontal: true } }
];

export const SETTINGS = {
    default: {
        common: [
            ...commonHeaderOptions
        ],
        header: [
            { name: 'favicon', value: '/favicon.ico', component: FaviconLoader, label: 'Favicon' },
            { name: 'backgroundImage', value: null, component: Image, label: 'Фон', props: { crop: true }},
            { name: 'backgroundImageMobile', value: null, component: Image, label: 'Фон для мобильной версии (вертикальная ориентация)', props: { crop: true }},
            { name: 'backgroundImageMobileHorizontal', value: null, component: Image, label: 'Фон для мобильной версии (горизонтальная ориентация)', props: { crop: true }},
            { name: 'backgroundPosition', value: 'center', component: BackgroundPosition, label: 'Выравнивание фона', isHidden: isHiddenBackgroundPosition },
            ...logoSettings,
            { name: 'background', value: '#0B567C', component: ColorPicker, label: 'Цвет фона' },
            { name: 'mainBackground', value: '#EDF5F9', component: ColorPicker, label: 'Цвет подложки' },
            { name: 'borderBottomColor', value: '#2C89DB', component: ColorPicker, label: 'Цвет нижней границы' },
            { name: 'btnBackground', value: '#2C89DB', component: ColorPicker, label: 'Цвет кнопки' },
            { name: 'btnHoverBackground', value: '#207ed2', component: ColorPicker, label: 'Цвет кнопки в фокусе' },
            { name: 'btnTextColor', value: '#fff', component: ColorPicker, label: 'Цвет текста кнопки' },
            { name: 'btnText', value: 'Заполни анкету кандидата', component: Text, label: 'Текст кнопки' }
        ],
        options: [
            { name: 'background', value: '#F6FAFD', component: ColorPicker, label: 'Цвет фона' }
        ],
        content: [
            { name: 'background', value: '#FFF', component: ColorPicker, label: 'Цвет фона' },
            { name: 'bulletType', value: 'check-circle', component: Bullet, label: 'Тип буллета' },
            { name: 'bulletColor', value: '#2C89DB', component: ColorPicker, label: 'Цвет буллета' }
        ],
        footer: [
            { name: 'background', value: '#416687', component: ColorPicker, label: 'Цвет фона' },
            { name: 'btnBackground', value: '#2C89DB', component: ColorPicker, label: 'Цвет кнопки' },
            { name: 'btnHoverBackground', value: '#207ed2', component: ColorPicker, label: 'Цвет кнопки в фокусе' },
            { name: 'btnTextColor', value: '#fff', component: ColorPicker, label: 'Цвет текста кнопки' },
            { name: 'btnText', value: 'Заполни анкету кандидата', component: Text, label: 'Текст кнопки' }
        ]
    },
    red: {
        common: [
            ...commonHeaderOptions
        ],
        header: [
            { name: 'favicon', value: '/favicon.ico', component: FaviconLoader, label: 'Favicon' },
            { name: 'backgroundImage', value: redBg, component: Image, label: 'Фон', props: { crop: true }},
            { name: 'backgroundImageMobile', value: null, component: Image, label: 'Фон для мобильной версии (вертикальная ориентация)', props: { crop: true }},
            { name: 'backgroundImageMobileHorizontal', value: null, component: Image, label: 'Фон для мобильной версии (горизонтальная ориентация)', props: { crop: true }},
            { name: 'backgroundPosition', value: 'center', component: BackgroundPosition, label: 'Выравнивание фона', isHidden: isHiddenBackgroundPosition },
            ...logoSettings,
            { name: 'background', value: '#EDF5F9', component: ColorPicker, label: 'Цвет фона' },
            { name: 'mainBackground', value: '#EAEAEA', component: ColorPicker, label: 'Цвет Подложки' },
            { name: 'borderBottomColor', value: '#f40000', component: ColorPicker, label: 'Цвет нижней границы' },
            { name: 'btnBackground', value: '#f40000', component: ColorPicker, label: 'Цвет кнопки' },
            { name: 'btnHoverBackground', value: '#ce0000', component: ColorPicker, label: 'Цвет кнопки в фокусе' },
            { name: 'btnTextColor', value: '#fff', component: ColorPicker, label: 'Цвет текста кнопки' },
            { name: 'btnText', value: 'Заполни анкету кандидата', component: Text, label: 'Текст кнопки' }
        ],
        main: [
            { name: 'background', value: '#F6FAFD', component: ColorPicker, label: 'Цвет фона' }
        ],
        content: [
            { name: 'background', value: '#FFF', component: ColorPicker, label: 'Цвет фона' },
            { name: 'bulletType', value: 'heart', component: Bullet, label: 'Тип буллета' },
            { name: 'bulletColor', value: '#f40000', component: ColorPicker, label: 'Цвет буллета' },
            { name: 'btnBackground', value: '#f40000', component: ColorPicker, label: 'Цвет кнопки' },
            { name: 'btnHoverBackground', value: '#ce0000', component: ColorPicker, label: 'Цвет кнопки в фокусе' },
            { name: 'btnTextColor', value: '#fff', component: ColorPicker, label: 'Цвет текста кнопки' },
            { name: 'btnText', value: 'Заполняй анкету и мы свяжемся с тобой!', component: Text, label: 'Текст кнопки' }
        ]
    },
    green: {
        common: [
            ...commonHeaderOptions
        ],
        header: [
            { name: 'favicon', value: '/favicon.ico', component: FaviconLoader, label: 'Favicon' },
            { name: 'backgroundImage', value: greenBg, component: Image, label: 'Фон', props: { crop: true }},
            { name: 'backgroundImageMobile', value: null, component: Image, label: 'Фон для мобильной версии (вертикальная ориентация)', props: { crop: true }},
            { name: 'backgroundImageMobileHorizontal', value: null, component: Image, label: 'Фон для мобильной версии (горизонтальная ориентация)', props: { crop: true }},
            { name: 'backgroundPosition', value: 'center', component: BackgroundPosition, label: 'Выравнивание фона', isHidden: isHiddenBackgroundPosition },
            ...logoSettings,
            { name: 'background', value: '#54BD68', component: ColorPicker, label: 'Цвет фона' },
            { name: 'mainBackground', value: '#EAEAEA', component: ColorPicker, label: 'Цвет Подложки' },
            { name: 'borderBottomColor', value: '#f8eb20', component: ColorPicker, label: 'Цвет нижней границы' },
            { name: 'btnBackground', value: '#f8eb20', component: ColorPicker, label: 'Цвет кнопки' },
            { name: 'btnHoverBackground', value: '#e8d811', component: ColorPicker, label: 'Цвет кнопки в фокусе' },
            { name: 'btnTextColor', value: '#333', component: ColorPicker, label: 'Цвет текста кнопки' },
            { name: 'btnText', value: 'Откликнуться', component: Text, label: 'Текст кнопки' }
        ],
        options: [
            { name: 'background', value: '#FFF', component: ColorPicker, label: 'Цвет фона' }
        ],
        content: [
            { name: 'background', value: '#FFF', component: ColorPicker, label: 'Цвет фона' },
            { name: 'bulletType', value: 'check', component: Bullet, label: 'Тип буллета' },
            { name: 'bulletColor', value: '#66c05d', component: ColorPicker, label: 'Цвет буллета' },
            { name: 'btnBackground', value: '#f8eb20', component: ColorPicker, label: 'Цвет кнопки' },
            { name: 'btnHoverBackground', value: '#e8d811', component: ColorPicker, label: 'Цвет кнопки в фокусе' },
            { name: 'btnTextColor', value: '#333', component: ColorPicker, label: 'Цвет текста кнопки' },
            { name: 'btnText', value: 'Откликнуться', component: Text, label: 'Текст кнопки' }
        ]
    },
    blue: {
        common: [
            ...commonHeaderOptions
        ],
        header: [
            { name: 'favicon', value: '/favicon.ico', component: FaviconLoader, label: 'Favicon' },
            { name: 'backgroundImage', value: blueBg, component: Image, label: 'Фон', props: { crop: true }},
            { name: 'backgroundImageMobile', value: null, component: Image, label: 'Фон для мобильной версии (вертикальная ориентация)', props: { crop: true }},
            { name: 'backgroundImageMobileHorizontal', value: null, component: Image, label: 'Фон для мобильной версии (горизонтальная ориентация)', props: { crop: true }},
            { name: 'backgroundPosition', value: 'center', component: BackgroundPosition, label: 'Выравнивание фона', isHidden: isHiddenBackgroundPosition },
            ...logoSettings,
            { name: 'background', value: '#008ADC', component: ColorPicker, label: 'Цвет фона' },
            { name: 'mainBackground', value: '#FFF', component: ColorPicker, label: 'Цвет Подложки' },
            { name: 'borderBottomColor', value: '#70AFF1', component: ColorPicker, label: 'Цвет нижней границы' }
        ],
        description: [
            { name: 'background', value: '#003491', component: ColorPicker, label: 'Цвет фона' },
            { name: 'btnBackground', value: '#fff', component: ColorPicker, label: 'Цвет кнопки' },
            { name: 'btnHoverBackground', value: '#fff', component: ColorPicker, label: 'Цвет кнопки в фокусе' },
            { name: 'btnTextColor', value: '#0a2896', component: ColorPicker, label: 'Цвет текста кнопки' },
            { name: 'btnHoverTextColor', value: '#eb0029', component: ColorPicker, label: 'Цвет текста кнопки в фокусе' },
            { name: 'btnText', value: 'Заполни анкету кандидата', component: Text, label: 'Текст кнопки' }
        ],
        content: [
            { name: 'backgroundImage', value: bluePhoto, component: Image, label: 'Фон' },
            { name: 'background', value: '#F6F7F9', component: ColorPicker, label: 'Цвет фона' },
            { name: 'bulletType', value: 'check', component: Bullet, label: 'Тип буллета' },
            { name: 'bulletColor', value: '#003D8C', component: ColorPicker, label: 'Цвет буллета' }
        ],
        footer: [
            { name: 'background', value: '#003491', component: ColorPicker, label: 'Цвет фона' },
            { name: 'btnBackground', value: '#fff', component: ColorPicker, label: 'Цвет кнопки' },
            { name: 'btnHoverBackground', value: '#fff', component: ColorPicker, label: 'Цвет кнопки в фокусе' },
            { name: 'btnTextColor', value: '#0a2896', component: ColorPicker, label: 'Цвет текста кнопки' },
            { name: 'btnHoverTextColor', value: '#eb0029', component: ColorPicker, label: 'Цвет текста кнопки в фокусе' },
            { name: 'btnText', value: 'Заполни анкету кандидата', component: Text, label: 'Текст кнопки' }
        ]
    },
    ancor: {
        common: [
            ...commonHeaderOptions
        ],
        header: [
            { name: 'favicon', value: '/ancorFavicon.ico', component: FaviconLoader, label: 'Favicon' },
            { name: 'backgroundImage', value: ancorBg, component: Image, label: 'Фон', props: { crop: true }},
            { name: 'backgroundImageMobile', value: null, component: Image, label: 'Фон для мобильной версии (вертикальная ориентация)', props: { crop: true }},
            { name: 'backgroundImageMobileHorizontal', value: null, component: Image, label: 'Фон для мобильной версии (горизонтальная ориентация)', props: { crop: true }},
            { name: 'backgroundPosition', value: 'center', component: BackgroundPosition, label: 'Выравнивание фона', isHidden: isHiddenBackgroundPosition },
            { name: 'btnText', value: 'Заполни анкету кандидата', component: Text, label: 'Текст кнопки' }
        ],
        footer: [
            { name: 'btnText', value: 'Заполни анкету кандидата', component: Text, label: 'Текст кнопки' }
        ]
    },
    gray: {
        common: [
            ...commonHeaderOptions
        ],
        header: [
            { name: 'favicon', value: '/favicon.ico', component: FaviconLoader, label: 'Favicon' },
            { name: 'backgroundImage', value: grayBg, component: Image, label: 'Фон', props: { crop: true }},
            { name: 'backgroundImageMobile', value: null, component: Image, label: 'Фон для мобильной версии (вертикальная ориентация)', props: { crop: true }},
            { name: 'backgroundImageMobileHorizontal', value: null, component: Image, label: 'Фон для мобильной версии (горизонтальная ориентация)', props: { crop: true }},
            { name: 'backgroundPosition', value: 'center', component: BackgroundPosition, label: 'Выравнивание фона', isHidden: isHiddenBackgroundPosition },
            ...logoSettings,
            { name: 'background', value: '#ccc', component: ColorPicker, label: 'Цвет фона' },
            { name: 'mainBackground', value: '#FFF', component: ColorPicker, label: 'Цвет Подложки' },
            { name: 'borderBottomColor', value: '#03a9f4', component: ColorPicker, label: 'Цвет нижней границы' },
            { name: 'btnBackground', value: '#03a9f4', component: ColorPicker, label: 'Цвет кнопки' },
            { name: 'btnHoverBackground', value: '#1397d8', component: ColorPicker, label: 'Цвет кнопки в фокусе' },
            { name: 'btnTextColor', value: '#fff', component: ColorPicker, label: 'Цвет текста кнопки' },
            { name: 'btnText', value: 'Заполни анкету кандидата', component: Text, label: 'Текст кнопки' },
            { name: 'hideFooter', value: true, component: Checkbox, label: 'Скрыть подвал', hideLabel: true }
        ],
        main: [
            { name: 'background', value: '#F2F6FF', component: ColorPicker, label: 'Цвет фона' },
            { name: 'mode', value: 'text', component: GrayMainModePicker, label: 'Режим' }
        ],
        vacancies: [
            { name: 'background', value: '#F6F7F9', component: ColorPicker, label: 'Цвет фона' },
            { name: 'btnViewBackground', value: '#7f9faf', component: ColorPicker, label: 'Цвет кнопки просмотра' },
            { name: 'btnViewHoverBackground', value: '#718f9e', component: ColorPicker, label: 'Цвет кнопки просмотра в фокусе' },
            { name: 'btnViewTextColor', value: '#fff', component: ColorPicker, label: 'Цвет текста кнопки просмотра' },
            { name: 'btnViewText', value: 'Подробнее', component: Text, label: 'Текст кнопки просмотра' },
            { name: 'btnBackground', value: '#03a9f4', component: ColorPicker, label: 'Цвет кнопки' },
            { name: 'btnHoverBackground', value: '#1397d8', component: ColorPicker, label: 'Цвет кнопки в фокусе' },
            { name: 'btnTextColor', value: '#fff', component: ColorPicker, label: 'Цвет текста кнопки' },
            { name: 'btnText', value: 'Заполни анкету кандидата', component: Text, label: 'Текст кнопки' },
            { name: 'vacancyColor', value: '#698998', component: ColorPicker, label: 'Цвет заголовка вакансии' }
        ],
        modal: [
            { name: 'background', value: '#fff', component: ColorPicker, label: 'Цвет фона' },
            { name: 'bulletType', value: 'check', component: Bullet, label: 'Тип буллета' },
            { name: 'bulletColor', value: '#7f9faf', component: ColorPicker, label: 'Цвет буллета' },
            { name: 'vacancies', value: {}, component: VacancySelector, label: 'Привязать вакансию', isHidden: isHiddenVacancySelector }
        ],
        footer: [
            { name: 'background', value: '#416687', component: ColorPicker, label: 'Цвет фона' },
            { name: 'btnBackground', value: '#03a9f4', component: ColorPicker, label: 'Цвет кнопки' },
            { name: 'btnHoverBackground', value: '#1397d8', component: ColorPicker, label: 'Цвет кнопки в фокусе' },
            { name: 'btnTextColor', value: '#fff', component: ColorPicker, label: 'Цвет текста кнопки' },
            { name: 'btnText', value: 'Заполни анкету кандидата', component: Text, label: 'Текст кнопки' }
        ]
    },
    lime: {
        common: [
            ...commonHeaderOptions
        ],
        header: [
            { name: 'favicon', value: '/favicon.ico', component: FaviconLoader, label: 'Favicon' },
            { name: 'backgroundImage', value: limeBg, component: Image, label: 'Фон', props: { crop: true }},
            { name: 'backgroundImageMobile', value: null, component: Image, label: 'Фон для мобильной версии (вертикальная ориентация)', props: { crop: true }},
            { name: 'backgroundImageMobileHorizontal', value: null, component: Image, label: 'Фон для мобильной версии (горизонтальная ориентация)', props: { crop: true }},
            { name: 'backgroundPosition', value: 'center', component: BackgroundPosition, label: 'Выравнивание фона', isHidden: isHiddenBackgroundPosition },
            ...logoSettings,
            { name: 'background', value: '#ccc', component: ColorPicker, label: 'Цвет фона' },
            { name: 'btnBackground', value: '#8ac541', component: ColorPicker, label: 'Цвет кнопки' },
            { name: 'btnHoverBackground', value: '#8ac541', component: ColorPicker, label: 'Цвет кнопки в фокусе' },
            { name: 'btnTextColor', value: '#fff', component: ColorPicker, label: 'Цвет текста кнопки' },
            { name: 'btnText', value: 'ЗАПОЛНИ АНКЕТУ КАНДИДАТА', component: Text, label: 'Текст кнопки' }
        ],
        description: [
            { name: 'background', value: '#fff', component: ColorPicker, label: 'Цвет фона' }
        ],
        options: [
            { name: 'background', value: '#fff', component: ColorPicker, label: 'Цвет фона' },
            { name: 'rows', value: 1, component: SelectRows, label: 'Количество рядов' }
        ],
        vacancies: [
            { name: 'background', value: '#fff', component: ColorPicker, label: 'Цвет фона' },
            { name: 'block', value: true, component: Checkbox, label: 'Показывать вакансии блоками', hideLabel: true },
            { name: 'blockText', value: true, component: Checkbox, label: 'Показывать текст слева', hideLabel: true, isHidden: propEq('block', true) },
            { name: 'vacancyBackground', value: '#19434e', component: ColorPicker, label: 'Цвет фона вакансии' },
            { name: 'vacancyBorder', value: '#0b343e', component: ColorPicker, label: 'Цвет обводки вакансии' },
            { name: 'btnViewBackground', value: '#0b343e', component: ColorPicker, label: 'Цвет кнопки просмотра', isHidden: isHiddenLimeVacancy },
            { name: 'btnViewHoverBackground', value: '#0b343e', component: ColorPicker, label: 'Цвет кнопки просмотра в фокусе', isHidden: isHiddenLimeVacancy },
            { name: 'btnViewTextColor', value: '#fff', component: ColorPicker, label: 'Цвет текста кнопки просмотра', isHidden: isHiddenLimeVacancy },
            { name: 'btnViewText', value: 'ПОДРОБНЕЕ', component: Text, label: 'Текст кнопки просмотра', isHidden: isHiddenLimeVacancy },
            { name: 'btnBackground', value: '#0b343e', component: ColorPicker, label: 'Цвет кнопки', isHidden: isHiddenLimeVacancy },
            { name: 'btnHoverBackground', value: '#0b343e', component: ColorPicker, label: 'Цвет кнопки в фокусе', isHidden: isHiddenLimeVacancy },
            { name: 'btnTextColor', value: '#fff', component: ColorPicker, label: 'Цвет текста кнопки', isHidden: isHiddenLimeVacancy },
            { name: 'btnText', value: 'ОТКЛИКНУТЬСЯ', component: Text, label: 'Текст кнопки', isHidden: isHiddenLimeVacancy },
            { name: 'vacancyColor', value: '#fff', component: ColorPicker, label: 'Цвет заголовка вакансии' }
        ],
        modal: [
            { name: 'background', value: '#fff', component: ColorPicker, label: 'Цвет фона' },
            { name: 'bulletType', value: 'check', component: Bullet, label: 'Тип буллета' },
            { name: 'bulletColor', value: '#8ac541', component: ColorPicker, label: 'Цвет буллета' },
            { name: 'vacancies', value: {}, component: VacancySelector, label: 'Привязать вакансию', isHidden: isHiddenVacancySelector }
        ],
        footer: [
            { name: 'background', value: '#0b343e', component: ColorPicker, label: 'Цвет фона' },
            { name: 'btnBackground', value: '#8ac541', component: ColorPicker, label: 'Цвет кнопки' },
            { name: 'btnHoverBackground', value: '#8ac541', component: ColorPicker, label: 'Цвет кнопки в фокусе' },
            { name: 'btnTextColor', value: '#fff', component: ColorPicker, label: 'Цвет текста кнопки' },
            { name: 'btnText', value: 'ЗАПОЛНИ АНКЕТУ КАНДИДАТА', component: Text, label: 'Текст кнопки' }
        ]
    },
    iceberg: {
        common: [
            ...commonHeaderOptions
        ],
        header: [
            { name: 'favicon', value: '/favicon.ico', component: FaviconLoader, label: 'Favicon' },
            { name: 'backgroundImage', value: blueBg, component: Image, label: 'Фон' },
            { name: 'backgroundPosition', value: 'center', component: BackgroundPosition, label: 'Выравнивание фона', isHidden: isHiddenBackgroundPosition },
            ...logoSettings,
            { name: 'background', value: '#008ADC', component: ColorPicker, label: 'Цвет фона' },
            { name: 'mainBackground', value: '#FFF', component: ColorPicker, label: 'Цвет Подложки' },
            { name: 'borderBottomColor', value: '#70AFF1', component: ColorPicker, label: 'Цвет нижней границы' }
        ],
        description: [
            { name: 'background', value: '#003491', component: ColorPicker, label: 'Цвет фона' },
            { name: 'btnBackground', value: '#fff', component: ColorPicker, label: 'Цвет кнопки' },
            { name: 'btnHoverBackground', value: '#fff', component: ColorPicker, label: 'Цвет кнопки в фокусе' },
            { name: 'btnTextColor', value: '#0a2896', component: ColorPicker, label: 'Цвет текста кнопки' },
            { name: 'btnHoverTextColor', value: '#eb0029', component: ColorPicker, label: 'Цвет текста кнопки в фокусе' },
            { name: 'btnText', value: 'Заполни анкету кандидата', component: Text, label: 'Текст кнопки' }
        ],
        options1: [
            { name: 'background', value: '#fff', component: ColorPicker, label: 'Цвет фона' },
            { name: 'rows', value: 1, component: SelectRows, props: { field: 'options1.options' }, label: 'Количество рядов' },
            { name: 'elements', value: 3, component: SelectElementsAmount, props: { field: 'options1.options' }, label: 'Количество элементов в ряде' },
            { name: 'hide', value: false, component: Checkbox, label: 'Не отображать в макете', hideLabel: true }
        ],
        content: [
            { name: 'backgroundImage', value: bluePhoto, component: Image, label: 'Фон' },
            { name: 'background', value: '#F6F7F9', component: ColorPicker, label: 'Цвет фона' },
            { name: 'bulletType', value: 'check', component: Bullet, label: 'Тип буллета' },
            { name: 'bulletColor', value: '#003D8C', component: ColorPicker, label: 'Цвет буллета' },
            { name: 'hide', value: false, component: Checkbox, label: 'Не отображать в макете', hideLabel: true }
        ],
        options2: [
            { name: 'background', value: '#fff', component: ColorPicker, label: 'Цвет фона' },
            { name: 'rows', value: 1, component: SelectRows, props: { field: 'options2.options' }, label: 'Количество рядов' },
            { name: 'elements', value: 3, component: SelectElementsAmount, props: { field: 'options2.options' }, label: 'Количество элементов в ряде' },
            { name: 'hide', value: false, component: Checkbox, label: 'Не отображать в макете', hideLabel: true }
        ],
        options3: [
            { name: 'background', value: '#fff', component: ColorPicker, label: 'Цвет фона' },
            { name: 'rows', value: 1, component: SelectRows, props: { field: 'options3.options' }, label: 'Количество рядов' },
            { name: 'elements', value: 3, component: SelectElementsAmount, props: { field: 'options3.options' }, label: 'Количество элементов в ряде' },
            { name: 'hide', value: false, component: Checkbox, label: 'Не отображать в макете', hideLabel: true }
        ],
        footer: [
            { name: 'background', value: '#003491', component: ColorPicker, label: 'Цвет фона' },
            { name: 'btnBackground', value: '#fff', component: ColorPicker, label: 'Цвет кнопки' },
            { name: 'btnHoverBackground', value: '#fff', component: ColorPicker, label: 'Цвет кнопки в фокусе' },
            { name: 'btnTextColor', value: '#0a2896', component: ColorPicker, label: 'Цвет текста кнопки' },
            { name: 'btnHoverTextColor', value: '#eb0029', component: ColorPicker, label: 'Цвет текста кнопки в фокусе' },
            { name: 'btnText', value: 'Заполни анкету кандидата', component: Text, label: 'Текст кнопки' }
        ]
    }
};
