import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ClickOutside from 'react-click-outside';
import CustomScroll from 'react-custom-scroll';
import cx from 'classnames';

import withFormat from './withFormat';

class Collapsed extends Component {
    static propTypes = {
        children: PropTypes.func,
        button: PropTypes.func,
        className: PropTypes.string,
        type: PropTypes.string
    };

    state = {
        opened: false
    };

    close = () => {
        if (this.state.opened) {
            this.setState({ opened: false });
        }
    }

    toggle = () => {
        this.setState(prev => ({ opened: !prev.opened }));
    }

    onSelect = value => {
        const { type, format } = this.props;

        format(type, value);
    }

    getValue() {
        const { type, formats } = this.props;

        return formats[type] || '';
    }

    render() {
        const { children, button, className, allowScroll, scrollTo } = this.props;
        const value = this.getValue();
        const content = children({
            value,
            onSelect: this.onSelect,
            close: () => this.setState({ opened: false })
        });

        return <ClickOutside onClickOutside={this.close} className={className}>
            <button onClick={this.toggle}>
                { button(value) }
            </button>
            { this.state.opened &&
                <div className={cx('dropdown-content', { 'dropdown-content-with-scroll': allowScroll })}>
                    { allowScroll ?
                        <CustomScroll heightRelativeToParent='100%' scrollTo={scrollTo && scrollTo(value)}>
                            <div className='custom-scroll-content'>
                                { content }
                            </div>
                        </CustomScroll> :
                        content
                    }
                </div>
            }
        </ClickOutside>
    }
}

export default withFormat(Collapsed);
