import React, { Component } from 'react';
import { Select } from 'antd';
import styled from 'styled-components';
import { find, propEq, assocPath, dissocPath, pathOr } from 'ramda';
import DataContext from '../../context/DataContext';

const StyledSelect = styled(Select)`
    width: 100%;
    border-radius: 1px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
    margin-top: 10px !important;

    .ant-select-selection-selected-value {
        margin-top: 0 !important;
    }

    .ant-select-selection__placeholder {
        left: 9px;
    }

    .ant-select-selection__clear {
        right: 30px;
    }
`;

export default class VacancySelector extends Component {
    onChange = (vacancy, onDataChange) => {
        const { params: { vacancies }, onChange, value, options: { path, titleWrapper }} = this.props;
        const arrPath = path.split('.');

        onChange(vacancy ? assocPath(arrPath, vacancy, value) : dissocPath(arrPath, value));

        if (vacancy) {
            const { title, description } = find(propEq('id', vacancy), vacancies);

            onDataChange(`vacancies.data.${path}`, {
                content: description,
                title: titleWrapper ? titleWrapper(title) : title
            });
        }
    }

    render() {
        const { params: { vacancies }, options: { path }, value } = this.props;

        return <DataContext.Consumer>
            { ({ onChange }) =>
                <StyledSelect
                    value={pathOr(undefined, path.split('.'), value)}
                    onChange={value => this.onChange(value, onChange)}
                    placeholder='Выбрать вакансию'
                    allowClear>
                    { vacancies.map(vacancy =>
                        <Select.Option key={vacancy.id} value={vacancy.id}>
                            { vacancy.title }
                        </Select.Option>
                    )}
                </StyledSelect>
            }
        </DataContext.Consumer>
    }
}
