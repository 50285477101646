import React, { Component } from 'react';
import { Image } from 'react-konva';
import { equals } from 'ramda';

import { OG_WIDTH, OG_HEIGHT } from '../../constants/og';

export default class ImageComponent extends Component {
    static defaultProps = {
        width: OG_WIDTH,
        height: OG_HEIGHT
    };

    state = {
        image: null
    };

    componentDidMount() {
        this.setImage();
    }

    componentDidUpdate(prev) {
        if (!equals(prev.image, this.props.image)) {
            this.setImage();
        }
    }

    setImage = () => {
        const image = new window.Image();

        image.src = this.props.image;
        image.onload = () => this.setState({ image });
    }

    getProps = () => {
        const { image } = this.state;

        if (!image) {
            return {};
        }

        const { width, height } = this.props;
        const { naturalWidth, naturalHeight } = image;
        const modifiedHeight = naturalHeight / (naturalWidth / width);
        const modifiedWidth = naturalWidth / (naturalHeight / height);
        const acrossWidth = height > modifiedWidth || (height < modifiedHeight && modifiedWidth < width);

        return acrossWidth ? {
            width,
            height: modifiedHeight,
            y: -modifiedHeight / 2 + (height / 2)
        } : {
            height,
            width: modifiedWidth,
            x: -modifiedWidth / 2 + (width / 2)
        };
    }

    render() {
        const { image } = this.state;

        return <Image
            image={image}
            {...this.getProps()} />
    }
}
