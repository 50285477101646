import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { prop, startsWith } from 'ramda';
import { SketchPicker } from 'react-color';
import ClickOutside from 'react-click-outside';

const Swatch = styled.div`
    background-color: ${prop('background')};
`;

export default class ColorPicker extends PureComponent {
    static propTypes = {
        value: PropTypes.string,
        onChange: PropTypes.func
    };

    constructor(props) {
        super(props);

        this.state = {
            color: this.getColorObject(props.value),
            opened: false,
        };
    }

    onToggle = () => this.setState(prev => ({ opened: !prev.opened }));

    onChange = color => {
        const colorObject = color.rgb.a < 1 ? ({ ...color.rgb }) : ({ hex: color.hex });
        this.props.onChange(this.getColorString(color));
        this.setState({ color: colorObject });
    };

    onClose = () => this.setState({ opened: false });

    getColorObject = (colorString) => {
        const isHex = startsWith('#', colorString);

        if (isHex) {
            return ({ hex: colorString });
        } else {
            const rgbaRegExp = /rgba\((\d+), (\d+), (\d+), ([\d.]+)\)/;
            const rgbaGroups = colorString.match(rgbaRegExp);

            return ({ r: rgbaGroups[1], g: rgbaGroups[2], b: rgbaGroups[3], a: rgbaGroups[4] });
        }
    }

    getColorString = (colorObject) => {
        const { rgb: { r, g, b, a = 1}, hex } = colorObject;

        if (a < 1) {
            return `rgba(${r}, ${g}, ${b}, ${a})`;

        } else {
            return hex;
        }
    }

    render() {
        const { value } = this.props;
        const { color, opened } = this.state;

        return <div className='color-picker'>
            <div className='color-picker-swatch' onClick={this.onToggle}>
                <Swatch className='color-picker-swatch-color' background={value} />
            </div>
            { opened &&
                <div className='color-picker-popover'>
                    <ClickOutside onClickOutside={this.onClose}>
                        <SketchPicker
                            color={color}
                            onChangeComplete={this.onChange}
                        />
                    </ClickOutside>
                </div>
            }
        </div>;
    }
}
