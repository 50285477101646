import React, { Component } from 'react';

import ToolbarContext from "./ToolbarContext";
import ToolbarEditContext from './ToolbarEditContext';

export default WrappedComponent =>
    class extends Component {
        state = {
            toolbar: null
        };

        setToolbar = toolbar => this.setState({ toolbar });

        render() {
            return <ToolbarContext.Provider value={this.state.toolbar}>
                <ToolbarEditContext.Provider value={this.setToolbar}>
                    <WrappedComponent {...this.props} setToolbar={this.setToolbar} />
                </ToolbarEditContext.Provider>
            </ToolbarContext.Provider>;
        }
    }
