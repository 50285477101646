import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'antd';

export default class CheckboxComponent extends PureComponent {
    static propTypes = {
        onChange: PropTypes.func,
        value: PropTypes.bool,
        label: PropTypes.string
    };

    onChange = e => this.props.onChange(e.target.checked);

    render() {
        return <Checkbox
            style={{ marginBottom: 10 }}
            checked={this.props.value}
            onChange={this.onChange}>
            { this.props.label }
        </Checkbox>;
    }
}
