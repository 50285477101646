export const ANCOR_CONTENT_HEADER_HTML = `
    <h1 class="ql-align-center">
        <span style="color: #f54d2e; ">Менеджер по работе с клиентами</span>
    </h1>
`;

export const ANCOR_CONTENT_HTML = `
    <h2>Что нам нужно:</h2>
    <ul>
        <li><span>Опыт работы в автокредитовании на территории официального дилерского центра от 0,5 года;</span></li>
        <li><span>Законченное высшее образование по направлению: экономика, финансы, банковское дело, менеджмент является желательным;</span></li>
        <li><span>Желание развиваться в финансовой сфере;</span></li>
        <li><span>Навыки продаж кредитных продуктов различным категориям клиентов;</span></li>
        <li><span>Интерес к сфере автомобильного бизнеса;</span></li>
        <li><span>Умение находить общий язык с разными людьми, доброжелательность, клиентоориентированность.</span></li>
    </ul>
    <h2>Что нужно будет делать:</h2>
    <ul>
        <li><span>Консультирование клиентов по вопросам автокредитования на территории одного из официальных дилерских центров;</span></li>
        <li><span>Прием заявок и их передача в центральный офис для последующей обработки и принятия решения;</span></li>
        <li><span>Информирование клиентов о принятом решении и последующее консультирование по процессу заключения кредитного договора;</span></li>
        <li><span>Подготовка и оформление кредитных сделок;</span></li>
        <li><span>Осуществление продаж дополнительных продуктов.</span></li>
    </ul>
    <h2>У тебя будет:</h2>
    <ul>
        <li><span>Стабильная работа в крупном банке;</span></li>
        <li><span>Современное оборудованное рабочее место;</span></li>
        <li><span>Белая заработная плата;</span></li>
        <li><span>Доход от 65 000 RUR;</span></li>
        <li><span>Работа на территории официального дилера премиального сегмента автомобилей;</span></li>
        <li><span>Возможность профессионального и личностного роста.</span></li>
    </ul>
`;

export const ANCOR_HEADER_HTML = `
    <h1>
        <span style="color: #fff;">Крупная страховая компания объявляет набор на позицию:</span>
    </h1>
`;

export const ANCOR_FOOTER_HTML = `
    <p>
        <span style="color: #000">Остались вопросы?</span>
    </p>
    <p>
        <span style="color: #000">Звони, мы с радостью ответим:<br />+7 (999) 777-00-11</span>
    </p>
`;

export default {
    header: ANCOR_HEADER_HTML,
    content: {
        header: ANCOR_CONTENT_HEADER_HTML,
        text: ANCOR_CONTENT_HTML
    },
    footer: {
        header: ANCOR_FOOTER_HTML
    }
};
