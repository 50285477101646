import React, { Component } from 'react';
import ReactQuill from 'react-quill';
import styled from 'styled-components';
import 'react-quill/dist/quill.snow.css';
import { faTextHeight } from '@fortawesome/free-solid-svg-icons';
import { equals } from 'ramda';

import { SIZE } from '../../constants/quill';
import Select from '../quill/Select';
import FormatContext from '../quill/FormatContext';

const formats = [
    'size',
    'bold', 'italic', 'underline', 'strike',
    'list', 'bullet'
];

const Wrapper = styled.div`
    margin-top: 10px;
    .ql-container {
        padding: 12px 10px;
    }
    .ql-select {
        position: relative;
        .dropdown-content-with-scroll {
            height: 175px;
            position: absolute;
            width: 110px;
            left: -9px;
            top: 33px;
            max-height: 295px;
            overflow-y: hidden;
            background: #fff;
            z-index: 1;
            border: 1px solid #ccc;
            button {
                width: 100%;
                &.selected {
                    color: #06c;
                }
            }
        }
    }
`;

const ToolbarButtonML = styled.button`
    margin-left: 10px;
`;

export default class Editor extends Component {
    state = {
        formats: {}
    };

    componentDidMount() {
        this.quill = this.reactQuill.getEditor();
        this.quill.on('selection-change', this.onSelectionChange);
    }

    componentWillUnmount() {
        this.quill.off('selection-change', this.onSelectionChange);
    }

    onSelectionChange = selectionRange => {
        if (selectionRange) {
            const formats = this.quill.getFormat(selectionRange);

            if (!equals(this.state.formats, formats)) {
                this.setState({ formats });
            }
        } else {
            this.setState({ formats: {} })
        }
    }

    format = (name, value) => {
        this.quill && this.quill.format(name, value)
    };

    render() {
        return <Wrapper>
            <FormatContext.Provider value={{
                    formats: this.state.formats,
                    format: this.format
                }}>
                <div id='toolbar'>
                    <Select
                        type='size'
                        icon={faTextHeight}
                        allowScroll
                        options={SIZE} />
                    <ToolbarButtonML className='ql-bold' />
                    <button className='ql-italic' />
                    <button className='ql-underline' />
                    <button className='ql-strike' />
                    <ToolbarButtonML className='ql-list' value='ordered' />
                    <button className='ql-list' value='bullet' />
                    <ToolbarButtonML className='ql-clean' />
                </div>
            </FormatContext.Provider>
            <ReactQuill
                ref={node => this.reactQuill = node}
                value={this.props.value}
                onChange={this.props.onChange}
                theme='snow'
                modules={{
                    toolbar: {
                        container: '#toolbar'
                    }
                }}
                formats={formats} />
        </Wrapper>;
    }
}
