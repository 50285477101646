import React, { Component } from 'react';
import Image from './Image';

export default class FaviconLoader extends Component {
    render() {
        return <div className='favicon-loader'>
            <Image {...this.props} accept='image/x-icon' />
        </div>;
    }
}
