import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Dropdown, Button, Menu, Checkbox } from 'antd';
import { prop, pathOr, append, without, contains, pathEq, propEq, find, any } from 'ramda';
import styled from 'styled-components';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faSpinner, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { DownOutlined } from '@ant-design/icons';

import botIcon from '../../assets/img/bot.ico';
import { BOT_ICONS } from '../../constants/bots';
import { checkLink } from '../../utils/bots';

const BotSelectField = styled.div`
    position: relative;
    width: 50%;
    float: left;
`;

const BotValidationField = styled(BotSelectField)`
    padding-left: 20px;
    font-size: 20px;
    line-height: 60px;
`;

const BotIcon = styled.span`
    display: inline-block;
    width: 26px;
    height: 21px;
    background-image: url('${prop('icon')}');
    background-size: cover;
    background-position: center;
    margin-left: -3px;
    margin-bottom: -3px;
`;

const BotSelect = styled.div`
    position: absolute;
    right: 0px;
    top: 8px;
    border: 1px solid #ccc;
    .ant-dropdown-trigger {
        height: 41px;
        width: 41px;
        border: none;
    }
`;

const BotItem = styled.div`
    padding: 2px 2px 2px 10px;
    svg,
    label {
        color: #0B2345;
    }
`;

export default class Text extends PureComponent {
    static propTypes = {
        onChange: PropTypes.func,
        disabled: PropTypes.bool,
        value: PropTypes.string
    };

    state = {
        visibleBots: false
    };

    onChange = e => this.props.onChange(e.target.value);

    selectBotLink = (e, { type, link }) => {
        const bots = pathOr([], ['bots'], this.props.settings);

        this.props.onChangeValue(type === 'jobot' ?
            (e.target.checked ? [link] : []) :
            e.target.checked ? append(link, bots) : without([link], bots), 'bots');
    }

    onVisibleChange = visibleBots => this.setState({ visibleBots });

    render() {
        const options = pathOr([], ['params', 'botLinks'], this.props);
        const bots = pathOr([], ['bots'], this.props.settings);

        return (
            <div>
                <BotSelectField>
                    <input
                        className='text-input'
                        type='text'
                        value={this.props.value || ''}
                        disabled={this.props.disabled}
                        onChange={this.onChange}
                    />
                    { options.length ? (
                        <BotSelect>
                            <Dropdown
                                visible={this.state.visibleBots}
                                onVisibleChange={this.onVisibleChange}
                                trigger={['click']}
                                overlay={
                                    <Menu>
                                        { options.map(item => {
                                            const icon = prop('icon', BOT_ICONS[item.type]);
                                            const image = icon ? <FontAwesomeIcon icon={icon} /> : <BotIcon icon={botIcon} />;

                                            return <BotItem key={item.type}>
                                                <Checkbox
                                                    key={item.type}
                                                    checked={contains(item.link, bots)}
                                                    onChange={e => this.selectBotLink(e, item)}
                                                    disabled={item.type !== 'jobot' && any(link => pathEq(['link'], link, find(propEq('type', 'jobot'), options)), bots)}>
                                                    { item.type === 'jobot' ? 'Все' : image }
                                                </Checkbox>
                                            </BotItem>;
                                        })}
                                    </Menu>
                                }>
                                <Button icon={<DownOutlined />} />
                            </Dropdown>
                        </BotSelect>
                    ) : null}
                </BotSelectField>
                <BotLinkValidator link={this.props.value} />
            </div>
        );
    }
}

class BotLinkValidator extends PureComponent {
    static propTypes = {
        link: PropTypes.string
    };

    state = {
        valid: false,
        pending: false,
    }

    componentDidMount() {
        this.checkLinkDebounce(this.props.link);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.link !== this.props.link) {
            this.checkLinkDebounce(this.props.link)
        }
    }

    checkLinkDebounce = (link) => {
        this.setState({ pending: true });

        if (this.timeout) {
            clearTimeout(this.timeout);
        }

        this.timeout = setTimeout(() => {
            checkLink(link)
                .then(() => {
                    this.setState({ valid: true });
                })
                .catch(() => {
                    this.setState({ valid: false });
                })
                .finally(() => {
                    this.setState({ pending: false });
                });
        }, 500);
    }

    componentWillUnmount() {
        clearTimeout(this.timeout);
    }

    render() {
        const { pending, valid } = this.state;
        const { link } = this.props;

        return (
            <BotValidationField>
                <Tooltip title={!pending && !valid && 'Некорректная ссылка'}>
                    { link ? (
                        <FontAwesomeIcon
                            className={cx({ 'fa-pulse': pending })}
                            icon={pending ? faSpinner : (valid ? faCheckCircle : faTimesCircle)}
                            color={valid ? 'green' : 'gray'}
                        />
                    ) : null }
                </Tooltip>
            </BotValidationField>
        );
    }
}
