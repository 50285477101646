import { Quill } from 'react-quill';

let Inline = Quill.import('blots/inline');
Inline.order = [...Inline.order, 'custom-background']

export default class CustomBackground extends Inline {
    static create(backgroundColor) {
        let node = super.create();
        node.style.boxShadow = `0 0 0 0.17em ${backgroundColor}`;
        node.style.backgroundColor = `${backgroundColor}`;

        return node;
    }

    static formats(domNode) {
        return domNode.style.backgroundColor;
    }

    format(name, value) {
        if (name === this.statics.blotName) {
            this.domNode.style.boxShadow = value ? `0 0 0 0.17em ${value}` : null;
            this.domNode.style.backgroundColor = value;
        } else {
            super.format(name, value);
        }
    }
}

CustomBackground.blotName = 'custom-background';
CustomBackground.tagName = 'span';
