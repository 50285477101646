import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import cx from 'classnames';
import { assocPath, filter, contains } from 'ramda';

import { SETTINGS } from '../../constants/settings';

export default class SettingsToolbar extends Component {
    static propTypes = {
        opened: PropTypes.string,
        template: PropTypes.string,
        settings: PropTypes.object,
        onSettingsChange: PropTypes.func,
        onClose: PropTypes.func,
        options: PropTypes.object
    };

    static defaultProps = {
        options: {}
    };

    getSettings = opened => this.props.settings[opened] || {};

    onChange = (value, name) => {
        this.props.onSettingsChange(assocPath([this.props.opened, name], value, this.props.settings));
    };

    getSettingsList = () => {
        const { options, opened, template } = this.props;
        const settings = SETTINGS[template][opened];

        return options.show ? filter(({ name }) => contains(name, options.show), settings) : settings;
    }

    renderSettingsField() {
        const { opened, params, options } = this.props;
        const settings = this.getSettings(opened);

        return settings && opened ? this.getSettingsList().map((item, index) => {
            const { label, component, name, hideLabel, isHidden, props = {} } = item;
            const Component = component;

            return isHidden && isHidden(settings, this.props, params) ? null :
                <div className='setting-group' key={`setting-${index}`}>
                    { !hideLabel && <p className='settings-label'>{ label }</p> }
                    <Component
                        {...props}
                        onChange={value => this.onChange(value, name)}
                        value={settings[name]}
                        name={name}
                        settings={settings}
                        label={label}
                        params={params}
                        options={options} />
                </div>;
        }) : null;
    }

    render() {
        const { opened, onClose } = this.props;

        return <Fragment>
            <div className={cx('settings-sidebar', { opened: !!opened })}>
                <h1>Настройки
                    <button className='close-btn' onClick={onClose}>
                        <FontAwesomeIcon icon={faTimes} />
                    </button>
                </h1>
                <div className='setting-body'>
                    { this.renderSettingsField() }
                </div>
            </div>
        </Fragment>
    }
}
