import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink, faCheckCircle } from '@fortawesome/free-solid-svg-icons';

import Collapsed from './Collapsed';

class LinkDropdown extends Component {
    state = {
        value: this.props.value
    };

    complete = () => {
        const { onSelect, close } = this.props;

        onSelect(this.state.value);
        close();
        this.setState({ value: null });
    }

    render() {
        return <Fragment>
            <input
                type='text'
                value={this.state.value || ''}
                onChange={e => this.setState({ value: e.target.value })} />
            <button onClick={this.complete}>
                <FontAwesomeIcon icon={faCheckCircle} />
            </button>
        </Fragment>;
    }
}

class Link extends Component {
    static propTypes = {
        type: PropTypes.string
    };

    render() {
        const { type } = this.props;

        return <Collapsed
            className='ql-link'
            type={type}
            button={() => <FontAwesomeIcon icon={faLink} />}>
            { ({ value, onSelect, close }) =>
                <LinkDropdown value={value} onSelect={onSelect} close={close} />
            }
        </Collapsed>;
    }
}

export default Link;
