import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

export default class Textarea extends PureComponent {
    static propTypes = {
        onChange: PropTypes.func,
        disabled: PropTypes.bool,
        value: PropTypes.string
    };

    onChange = e => this.props.onChange(e.target.value);

    render() {
        return <textarea
            className='text-input'
            value={this.props.value}
            disabled={this.props.disabled}
            onChange={this.onChange}
            rows={3} />;
    }
}
