export const RED_HEADER_HTML = `
    <h1 class="ql-align-center">
        <span style="color: rgb(255, 255, 255);">Вливайся в нашу команду!</span>
    </h1>
`;

export const RED_MAIN_HTML = `
    <p>
        <span style="color: #3d3d3d;">
            <strong>Наша компания</strong> – генеральный спонсор Чемпионата мира по футболу 2018 в России ищет активных, спортивных и свободных (на период с мая по июль) молодых парней и девушек на позицию <strong>мерчендайзера</strong>.
        </span>
    </p>
    <br />
    <p>
        <span style="color: #3d3d3d;">
            <strong>Твоя задача</strong> – организовать продуктивную работу нашей промо-точки в твоем городе в соответствии со стандартами мерчендайзинга нашей компании.
        </span>
    </p>
`;

export const RED_CONTENT_HTML_1 = `
    <h4>Что мы предлагаем?</h4>
    <br />
    <ul>
        <li>
            <span><b>Уникальную</b> возможность участия в проекте мирового масштаба</span>
        </li>
        <li>
            <span>Работу в <b>крупнейшей</b> компании-партнере чемпионата 2018</span>
        </li>
        <li>
            <span>Работу в молодой, динамичной команде</span>
        </li>
        <li>
            <span>Стильную брендированную <b>униформу</b> от нашей компании</span>
        </li>
        <li>
            <span>Ну и конечно, <b>официальное</b> оформление и <b>достойную</b> оплату труда</span>
        </li>
    </ul>
    <p class="ql-align-center"><b>Стань частью команды мечты и присоединись к событию, которое войдет в историю России и мира!</b></p>
`;

export const RED_CONTENT_HTML_2 = `
    <h6 class="ql-align-center">Нужны подробности?</h6>
`;

export const RED_CONTENT_HTML_3 = `
    <h5 class="ql-align-center">Ждем тебя на стадионах твоего города!</h5>
`;

export default {
    header: RED_HEADER_HTML,
    description: RED_MAIN_HTML,
    content: {
        text: RED_CONTENT_HTML_1,
        beforeButton: RED_CONTENT_HTML_2,
        afterButton: RED_CONTENT_HTML_3
    }
};
