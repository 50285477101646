import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SketchPicker } from 'react-color';
import { is, head, forEach, find, equals } from 'ramda';

import { PRESET_COLORS } from '../../constants/quill';
import Collapsed from './Collapsed';

class Picker extends Component {
    constructor(props) {
        super(props);

        this.state = {
            color: {
                hex: is(Array, props.value) ? head(props.value) : props.value
            },
            focused: false
        };
    }

    componentDidMount() {
        const inputs = this.container.querySelectorAll('input');

        forEach(input => {
            input.onclick = this.onFocusInput;
            input.onfocus = this.onFocusInput;
            input.onblur = this.onBlurInput;
        }, inputs);

        this.container.addEventListener('mousedown', this.onMouseDownContainer);
    }

    componentWillUnmount() {
        this.container.addEventListener('mousedown', this.onMouseDownContainer);
    }

    onMouseDownContainer = e => {
        if (e.target.tagName !== 'INPUT' && this.state.focused) {
            const input = find(equals(document.activeElement), this.container.querySelectorAll('input'));

            input.blur();
        }
    }

    onFocusInput = e => {
        if (!this.state.focused) {
            setTimeout(() => e.target.focus())
        }
        this.setState({ focused: true });
    }

    onBlurInput = () => {
        this.setState({ focused: false });

        if (this.props.value !== this.state.color.hex) {
            this.props.onSelect(this.state.color.hex);
        }
    }

    onSelect = color => {
        if (!this.state.focused) {
            this.props.onSelect(color.hex);
        }

        this.setState({ color });
    }

    render() {
        return <div ref={node => this.container = node}>
            <SketchPicker
                color={this.state.color}
                onChangeComplete={this.onSelect}
                presetColors={PRESET_COLORS}
                disableAlpha />
        </div>;
    }
}

class Color extends Component {
    static propTypes = {
        icon: PropTypes.object,
        type: PropTypes.string
    };

    render() {
        const { icon, type } = this.props;

        return <Collapsed
            type={type}
            className='ql-color-select'
            button={() => <FontAwesomeIcon icon={icon} />}>
            { ({ value, onSelect }) =>
                <Picker value={value} onSelect={onSelect} />
            }
        </Collapsed>;
    }
}

export default Color;
