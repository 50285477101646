import { Quill } from 'react-quill';
import { any } from 'ramda';

let Inline = Quill.import('blots/inline');
Inline.order = [...Inline.order, 'custom-link'];

const protocols = ['http://', 'https://', 'mailto:', 'tel:', '//'];

export default class CustomLink extends Inline {
    static create(url) {
        const node = super.create();
        const href = any(protocol => url.startsWith(protocol), protocols) ? url : `//${url}`;

        node.setAttribute('href', href);
        node.setAttribute('target', '_blank');
        node.dataset.url = url;

        return node;
    }

    static formats(node) {
        return node.dataset.url;
    }
}

CustomLink.blotName = 'custom-link';
CustomLink.tagName = 'a';
