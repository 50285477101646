import React, { Component } from 'react';
import ClickOutside from 'react-click-outside';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft, faArrowCircleUp, faArrowAltCircleDown, faArrowCircleRight, faDotCircle } from '@fortawesome/free-solid-svg-icons';
import { find, propEq, unnest } from 'ramda';

const Container = styled.div`
    position: relative;
    float: right;
    margin-bottom: 6px;
`;

const Toggler = styled.div`
    font-size: 20px;
    color: #2d89db;
    cursor: pointer;
    svg {
        transform: rotate(${({ angle }) => angle || 0}deg);
    }
`;

const Selector = styled.div`
    position: absolute;
    top: 34px;
    right: 0;
    background: #fff;
    z-index: 1001;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 0px 1px, rgba(0, 0, 0, 0.15) 0px 8px 16px;
    border-radius: 4px;
    padding: 3px 7px 7px;
`;

const Row = styled.div`
    display: flex;
`;

const SelectorIcon = styled.div`
    color: ${({ active }) => active ? '#2d89db' : 'rgba(0, 0, 0, 0.65)'};
    font-size: 20px;
    cursor: pointer;
    height: 24px;
    overflow: hidden;
    margin: 0 1px;
    transition: color .3s;
    &:hover {
        color: #2d89db;
    }
    svg {
        transform: rotate(${({ angle }) => angle || 0}deg);
    }
`;

const HORIZONTAL_POSITIONS = [[
    { type: 'left', icon: faArrowCircleLeft },
    { type: 'center', icon: faDotCircle },
    { type: 'right', icon: faArrowCircleRight }
]];

const POSITIONS = [
    [
        { type: 'top left', icon: faArrowCircleUp, angle: -45 },
        { type: 'top', icon: faArrowCircleUp },
        { type: 'top right', icon: faArrowCircleUp, angle: 45 }
    ],
    ...HORIZONTAL_POSITIONS,
    [
        { type: 'bottom left', icon: faArrowAltCircleDown, angle: 45 },
        { type: 'bottom', icon: faArrowAltCircleDown },
        { type: 'bottom right', icon: faArrowAltCircleDown, angle: -45 }
    ]
];

export default class BackgroundPosition extends Component {
    state = {
        opened: false
    };

    close = () => this.setState({ opened: false });

    toggle = () => this.setState(prev => ({ opened: !prev.opened }));

    getCurrent = () => {
        const { value, horizontal } = this.props;

        return find(propEq('type', value), unnest(horizontal ? HORIZONTAL_POSITIONS : POSITIONS)) || { type: 'center', icon: faDotCircle };
    }

    render() {
        const { onChange, value, horizontal } = this.props;
        const current = this.getCurrent();

        return <Container>
            <Toggler onClick={this.toggle} angle={current.angle}>
                <FontAwesomeIcon icon={current.icon} />
            </Toggler>
            <ClickOutside onClickOutside={this.close}>
                { this.state.opened &&
                    <Selector>
                        { (horizontal ? HORIZONTAL_POSITIONS : POSITIONS).map((row, index) =>
                            <Row key={`bg-position-row-${index}`}>
                                { row.map(position =>
                                    <SelectorIcon
                                        key={position.type}
                                        onClick={() => onChange(position.type)}
                                        active={value === position.type}
                                        angle={position.angle}>
                                        <FontAwesomeIcon icon={position.icon} />
                                    </SelectorIcon>
                                )}
                            </Row>
                        )}
                    </Selector>
                }
            </ClickOutside>
        </Container>
    }
}
