import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class IFrame extends Component {
    static propTypes = {
        data: PropTypes.object
    };

    componentDidMount() {
        this.iframe.onload = () => this.sendData();
    }

    sendData() {
        const { data, settings, template } = this.props;

        this.iframe.contentWindow.postMessage({
            data,
            settings,
            template
        }, '*');
    }

    render() {
        return <iframe
            ref={node => this.iframe = node}
            title='preview'
            src='/preview'
            width='100%'
            height='100%'
            frameBorder='0' /> ;
    }
}
