import React, { Component } from 'react';
import { Image } from 'react-konva';

export default class URLImage extends Component {
    state = {
        image: null
    };

    componentDidMount() {
        this.loadImage();
    }

    componentDidUpdate(oldProps) {
        if (oldProps.src !== this.props.src) {
            this.loadImage();
        }
    }

    loadImage() {
        let image = new window.Image();
        image.src = this.props.src;
        image.onload = () => {
            this.setState({ image });
            this.imageNode.getLayer().batchDraw();
        }
    }

    render() {
        return (
            <Image
                x={this.props.x}
                y={this.props.y}
                image={this.state.image}
                ref={node => {
                    this.imageNode = node;
                }}
            />
        );
    }
}
