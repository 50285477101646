import React, { PureComponent } from 'react';
import ReactQuill from 'react-quill';
import uniqid from 'uniqid';
import { forEach, equals } from 'ramda';

import Toolbar from './Toolbar';
import { getFormats } from '../../constants/quill';
import ToolbarContext from './ToolbarContext';
import ToolbarEditContext from './ToolbarEditContext';
import FormatContext from './FormatContext';

export default class QuillComponent extends PureComponent {
    id = uniqid();
    modules = {
        toolbar: {
            container: `#toolbar-${this.id}`
        }
    };
    state = {
        formats: {}
    };

    componentDidMount() {
        this.quill = this.reactQuill.getEditor();
        this.quill.on('selection-change', this.onSelectionChange);
        this.removeSpellCheck();
    }

    componentDidUpdate() {
        this.removeSpellCheck();
    }

    componentWillUnmount() {
        this.quill.off('selection-change', this.onSelectionChange);
    }

    onSelectionChange = selectionRange => {
        if (selectionRange) {
            const formats = this.quill.getFormat(selectionRange);

            if (!equals(this.state.formats, formats)) {
                this.setState({ formats });
            }
        } else {
            this.setState({ formats: {} })
        }
    }

    removeSpellCheck() {
        const tags = this.quill.root.querySelectorAll('*');
        forEach(tag => tag.setAttribute('spellcheck', false), tags);
    }

    onChange = html => {
        this.props.onChange(this.props.name, html);
    };

    format = (name, value) => {
        this.quill && this.quill.format(name, value)
    };

    render() {
        const { html, allowColor, allowLists, allowFontSize } = this.props;
        const formats = getFormats(allowColor, allowLists, allowFontSize);

        return <div>
            <ToolbarContext.Consumer>
                { toolbar =>
                    <FormatContext.Provider value={{
                        formats: this.state.formats,
                        format: this.format
                    }}>
                        <Toolbar
                            id={`toolbar-${this.id}`}
                            show={toolbar === this.id}
                            allowColor={allowColor}
                            allowLists={allowLists}
                            allowFontSize={allowFontSize} />
                    </FormatContext.Provider>
                }
            </ToolbarContext.Consumer>
            <ToolbarEditContext.Consumer>
                { setToolbar =>
                    <div
                        onMouseDown={e => {
                            e.stopPropagation();
                            setToolbar(this.id);
                        }}>
                        <ReactQuill
                            ref={node => this.reactQuill = node}
                            value={html}
                            onChange={this.onChange}
                            modules={this.modules}
                            formats={formats}
                            theme={null}
                        />
                    </div>
                }
            </ToolbarEditContext.Consumer>
        </div>;
    }
}
